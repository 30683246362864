import { useEffect, useMemo } from 'react';
import { Flag02LineIcon, Rocket02LineIcon } from '@konta/icons';
import usePendingTasksStore from 'store/pendingTasksStore';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';
import { useTaxableEntity } from '@hooks';
import { TaxableEntity } from 'types/entities';

const pendingProcessSteps = [
  'MeantimeOnboardingProcessStep',
  'UploadDataAnnualProcessStep',
  'RecommendationsAnnualProcessStep',
];

export default function useHandleProcess() {
  const { addPendingTask } = usePendingTasksStore();
  const { taxable_entity: taxableEntity } = useTaxableEntity() as {
    taxable_entity: TaxableEntity;
  };

  const { currentProcesses, loadingCurrentProcesses } =
    useCurrentProcessesQuery();

  const hasFirstInformation = useMemo(
    () => taxableEntity.status !== 'demo',
    [taxableEntity],
  );

  useEffect(() => {
    if (!loadingCurrentProcesses) {
      const sortedProcesses = currentProcesses
        .filter(
          (process) =>
            !process.completed && process.type !== 'FinishOnboardingProcess',
        )
        .sort((a, b) => a.id - b.id);

      const onboardingFinishProcess = currentProcesses.find(
        (process) => process.type === 'FinishOnboardingProcess',
      );
      const onboardingProcess = currentProcesses.find(
        (process) => process.type === 'OnboardingOnboardingProcess',
      );

      if (hasFirstInformation) {
        sortedProcesses.forEach((process) => {
          const isAnnualProcess = process.type === 'AnnualDeclarationProcess';
          const type =
            process.type === 'OnboardingOnboardingProcess'
              ? 'onboarding'
              : 'annualDeclaration';
          addPendingTask({
            id: `${type}-${process?.id || 0}`,
            type,
            status: pendingProcessSteps.includes(process.current_step || '')
              ? 'pending'
              : 'requiredAction',
            title: process?.name,
            description: isAnnualProcess
              ? 'Proceso anual de impuestos al finalizar el año'
              : process?.description,
            extraDescription: process,
            details: [
              {
                label: isAnnualProcess
                  ? 'Empieza tu declaración anual'
                  : 'Continúa con el proceso de apertura de cuenta',
                icon: isAnnualProcess ? (
                  <Rocket02LineIcon />
                ) : (
                  <Flag02LineIcon />
                ),
                iconCss: {
                  background: isAnnualProcess ? '$rose100' : '$orange100',
                  path: {
                    fill: 'none',
                    stroke: isAnnualProcess ? '$rose500' : '$orange500',
                  },
                },
              },
            ],
          });
        });
        if (
          onboardingFinishProcess &&
          onboardingProcess?.completed &&
          !onboardingFinishProcess?.completed
        ) {
          addPendingTask({
            id: `finishOnboarding-${onboardingFinishProcess?.id || 0}`,
            type: 'onboarding',
            status: 'pending',
            title: onboardingFinishProcess?.name || '',
            description: onboardingFinishProcess?.description || '',
            extraDescription: onboardingProcess,
            details: [
              {
                label: 'Continúa con el proceso de apertura de cuenta',
                icon: <Flag02LineIcon />,
                iconCss: {
                  background: '$orange100',
                  path: {
                    fill: 'none',
                    stroke: '$orange500',
                  },
                },
              },
            ],
          });
        }
      }
    }
  }, [
    addPendingTask,
    currentProcesses,
    hasFirstInformation,
    loadingCurrentProcesses,
  ]);

  return {
    loadingCurrentProcesses,
  };
}
