import { useEffect, useMemo } from 'react';
import usePendingTasksStore from 'store/pendingTasksStore';
import { shallow } from 'zustand/shallow';
import useHandleDebsOrders from './hooks/useHanleDebsOrders';
import useHandleWorkflows from './hooks/useHandleWorkflows';
import useHandleProcess from './hooks/useHandleProcess';
import useHandlePreOnboarding from './hooks/useHandlePreOnboarding';

export default function usePendingTask() {
  const { pendingTasks } = usePendingTasksStore((state) => state, shallow);
  const { cleanPendingTask, cleanSelectedTask } = usePendingTasksStore();

  // clean
  useEffect(
    () => () => {
      cleanPendingTask();
      cleanSelectedTask();
    },
    [cleanPendingTask, cleanSelectedTask],
  );

  // debts
  const {
    loadingOrders,
    showDebsOrderModalTask,
    toggleShowDebsOrderModalTask,
  } = useHandleDebsOrders();

  // unfinished processes
  const { loadingCurrentProcesses } = useHandleProcess();

  // pre onboarding
  const { showPreOnboardingModalTask, toggleShowPreOnboardingModalTask } =
    useHandlePreOnboarding();

  // workflows
  const { workflowsLoading } = useHandleWorkflows();

  const filteredPendingTasks = useMemo(
    () => ({
      requiredAction: pendingTasks.filter(
        (task) => task.status === 'requiredAction',
      ),
      pending: pendingTasks.filter((task) => task.status === 'pending'),
      completed: pendingTasks.filter((task) => task.status === 'completed'),
    }),
    [pendingTasks],
  );

  // handle Loading
  const tasksLoading = useMemo(
    () => loadingOrders || loadingCurrentProcesses || workflowsLoading,
    [loadingOrders, loadingCurrentProcesses, workflowsLoading],
  );

  return {
    showPreOnboardingModalTask,
    toggleShowPreOnboardingModalTask,
    toggleShowDebsOrderModalTask,
    showDebsOrderModalTask,
    filteredPendingTasks,
    tasksLoading,
  };
}
