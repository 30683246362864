import { ChevronDownCrFr1OutlineIcon } from '@konta/icons';
import { Fragment, useState } from 'react';
import dayjs from 'dayjs';
import { AnnualDeclaration, ProcessType } from 'types/entities';
import ProcessStepLayout from '@components/ProcessStepLayout';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Flex,
  Text,
} from '@konta/ui';
import useAnnualsDeclarationQuery from '@hooks/useAnnualsDeclarationQuery';
import useRoutesResumeAnnual from './useRoutesResumeAnnual';
import Wrapper from './Wrapper';
import ResumeByRegime from '../ResumeAnnual/ResumeByRegime';
import Entries from '../ResumeAnnual/Entries';
import ResumeAnnual from '../ResumeAnnual';

interface ContentComponentProps {
  customPeriod?: number;
  annualDeclarations?: AnnualDeclaration[];
  loadingAnnualDeclarations?: boolean;
}

function ContentComponent({
  customPeriod,
  annualDeclarations,
  loadingAnnualDeclarations,
}: ContentComponentProps) {
  const {
    view,
    views,
    onHandleEntriesView,
    resumeByRegimeBreadcrumbs,
    entriesBreadcrumbs,
    setView,
    onHandleResumeByRegime,
    filters,
    workflowsId,
    taxableEntity,
    workflows,
    regimeSatKeys,
    period,
    isLoading,
    currentAnnualDeclaration,
    currentAnnualDeclarations,
  } = useRoutesResumeAnnual({
    customPeriod,
    annualDeclarations,
    loadingAnnualDeclarations,
  });

  if (view === views.entries) {
    return (
      <Wrapper
        breadcrumbs={entriesBreadcrumbs}
        onClickBackBtn={() => setView(views.resumeByRegime)}
      >
        <Entries
          workflowsId={workflowsId}
          taxableEntity={taxableEntity}
          filters={filters}
          period={period}
        />
      </Wrapper>
    );
  }
  if (view === views.resumeByRegime) {
    return (
      <Wrapper
        breadcrumbs={resumeByRegimeBreadcrumbs}
        onClickBackBtn={() => setView(views.resume)}
      >
        <ResumeByRegime
          onHandleEntriesView={onHandleEntriesView}
          satKeys={regimeSatKeys}
          workflows={workflows}
          period={period}
          selectedAnnualDeclaration={currentAnnualDeclaration}
        />
      </Wrapper>
    );
  }
  return (
    <ResumeAnnual
      currentAnnualDeclarations={currentAnnualDeclarations || []}
      onHandleResumeByRegime={onHandleResumeByRegime}
      isLoading={isLoading}
      period={+period}
      customPeriod={!!customPeriod}
    />
  );
}

interface RoutesResumeAnnualProps {
  isProcess?: boolean;
}

export default function RoutesResumeAnnual({
  isProcess,
}: RoutesResumeAnnualProps) {
  const {
    loadingDeclaration: loadingAnnualDeclarations,
    declarations: annualDeclarations,
  } = useAnnualsDeclarationQuery();

  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const urlPeriod = params.get('period');

  let defaultPeriod = dayjs().year();
  if (urlPeriod) {
    defaultPeriod = +urlPeriod;
  } else if (isProcess) {
    defaultPeriod = dayjs().subtract(1, 'year').year();
  }

  const [period, setPeriod] = useState(defaultPeriod);

  if (!isProcess) {
    return (
      <Flex
        className="app-container"
        direction="column"
        style={{ background: '#ffffff' }}
        gap={10}
      >
        <Flex gap={12} justify="end">
          {!isProcess && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="text"
                  leftIcon={<ChevronDownCrFr1OutlineIcon />}
                  color="primary"
                  size="s"
                >
                  Cambiar periodo
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {annualDeclarations
                  .sort((a, b) => b.period - a.period)
                  .map((declaration) => (
                    <Fragment key={declaration.id}>
                      <DropdownMenuItem
                        key={declaration.id}
                        onClick={() => {
                          setPeriod(declaration.period);
                        }}
                      >
                        <Flex align="center">
                          <Text css={{ mr: '$16', ml: '$12' }}>
                            Declaración {declaration.period}
                          </Text>
                        </Flex>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                    </Fragment>
                  ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </Flex>
        <ContentComponent
          customPeriod={period}
          annualDeclarations={annualDeclarations}
          loadingAnnualDeclarations={loadingAnnualDeclarations}
        />
      </Flex>
    );
  }
  return (
    <ProcessStepLayout
      fullContent
      currentProcessType={ProcessType.AnnualDeclarationProcess}
      title="Declaración anual"
      titleChip="En curso"
      content={
        <ContentComponent
          annualDeclarations={annualDeclarations}
          loadingAnnualDeclarations={loadingAnnualDeclarations}
        />
      }
    />
  );
}
