import { QueryOptions, useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { DECLARATIONS } from '@constants/reactQueries';
import { Declaration } from 'types/entities';
import getDeclarations from '@api/getDeclarations';

export default function useDeclarations(
  config?: AxiosRequestConfig,
  queryOptions?: QueryOptions<Declaration[]>,
) {
  const {
    data = null,
    isLoading,
    ...rest
  } = useQuery<Declaration[]>(
    [DECLARATIONS, config],
    async () => {
      const declarations = await getDeclarations(config);
      return declarations;
    },
    {
      ...queryOptions,
    },
  );
  return {
    declarationsLoading: isLoading,
    declarations: data,
    ...rest,
  };
}
