import React, { useEffect, useState } from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import { Colxx, Emoji } from '@components';
import { connect } from 'react-redux';
import { Text } from '@konta/ui';
import dayjs from 'dayjs';
import {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
} from '@redux/actions';
import toCurrency from '@util/toCurrency';
import { getMonthAndYearDate } from '@util/Utils';
import getDeclarationTotalToPay from '@util/getDeclarationTotalToPay';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import useHasPayrollRetention from '@hooks/useHasPayrollRetention';
import { useTaxableEntity } from '@hooks/taxable_entity.js';
import Main from '@components/DesignSystem/Main';
import ExpiredCsdModal from '@components/ExpiredCsdModal/';
import { Root } from '@components/ExpiredCsdModal/styled';
import { IS_LOCAL, IS_PRODUCTION } from '@constants/enviroments';
import SalariesRetentionComputeResult from '../SalariesRetentionComputeResult.tsx';

function ComputeResult({ workflow }) {
  const [expiredCsd, setExpiredCsd] = useState(false);
  const { hasPayrollRetentions } = useHasPayrollRetention();
  const {
    total_income = 0,
    total_expenses = 0,
    iva_in_favor = 0,
    iva_total = 0,
    total_payroll_withholdings_to_pay = 0,
    declaration_income,
    total_late_fees = 0,
    undue_payment_iva_in_favor = 0,
  } = workflow?.active_declaration ?? {};
  const { taxes_withheld_of_payroll = 0, employment_subsidy_of_payroll = 0 } =
    declaration_income ?? {};
  const { nextPeriodIva, totalIvaToPay, totalIsrToPay, totalToPay } =
    getDeclarationTotalToPay(
      workflow?.active_declaration,
      hasPayrollRetentions,
    );
  const totalLateFees = +(total_late_fees || 0);
  const { taxable_entity } = useTaxableEntity();

  useEffect(() => {
    const csdExpirationDate = taxable_entity.csd_expiration_date;
    if (csdExpirationDate && dayjs(csdExpirationDate) < dayjs()) {
      setExpiredCsd(true);
    }
  }, [taxable_entity]);

  const isNextBtnEnabled = IS_PRODUCTION || IS_LOCAL;

  return (
    <Root>
      {expiredCsd && <ExpiredCsdModal />}

      <Main blockUi={expiredCsd}>
        <WorkflowLayout
          header={
            <WorkflowHeader
              title="Cálculo de impuestos"
              description="Con la información de los ingresos y gastos, el cálculo de los impuestos es simple. Sin embargo, un contador revisará tu declaración antes de presentarla para asegurarse de que todo esté en orden. Si notamos que hay algo mal, un contador se comunicará con usted por teléfono."
            />
          }
          actions={
            <>
              <PrevPhaseButton />
              <PrimaryPhaseButton disabled={!isNextBtnEnabled} />
            </>
          }
        >
          <Text x2l>
            Todo en orden
            <Emoji symbol="👍🏼" label="thumbs-up" />. Este es el resumen de sus
            impuestos del mes de{' '}
            {getMonthAndYearDate(new Date(workflow.start_date))}
          </Text>
          <div className="d-flex align-items-center w-100">
            <div className="w-100">
              <Row>
                <Colxx md="6">
                  <Card className="border border-theme-3 mb-4">
                    <CardBody>
                      <section className="mb-3">
                        <Row
                          className="font-weight-bold text-primary justify-content-around"
                          style={{ fontSize: '1rem' }}
                        >
                          <Colxx xxs="12" xs="6" md="5" lg="6">
                            <Card
                              body
                              className="border-primary border rounded my-2 my-lg-0 py-2 px-4"
                            >
                              <div
                                className="mt-2 mb-2"
                                style={{ height: '40px' }}
                              >
                                Ingresos
                              </div>
                              <span className="text-right">
                                {toCurrency(+total_income)}
                              </span>
                            </Card>
                          </Colxx>
                          <Colxx xxs="12" xs="6" md="5" lg="6">
                            <Card
                              body
                              className="border-primary border rounded my-2 my-lg-0 py-2 px-4"
                            >
                              <div
                                className="mt-2 mb-2"
                                style={{ height: '40px' }}
                              >
                                Gastos
                              </div>
                              <span className="text-right">
                                {toCurrency(+total_expenses)}
                              </span>
                            </Card>
                          </Colxx>
                        </Row>
                      </section>
                      <section>
                        <Row
                          className="font-weight-bold text-primary"
                          style={{ fontSize: '1rem' }}
                        >
                          <Colxx xxs={{ size: 7, offset: 0 }}>
                            IVA a favor de meses anteriores
                          </Colxx>
                          <Colxx
                            xxs={{ size: 'auto', offset: 2 }}
                            md={{ size: 3 }}
                          >
                            {toCurrency(+iva_in_favor)}
                          </Colxx>
                        </Row>

                        {/* Impuestos SS  */}
                        <Row
                          className="font-weight-bold text-primary"
                          style={{ fontSize: '1rem' }}
                        >
                          <Colxx xxs={{ size: 7, offset: 0 }}>
                            Resultado de IVA de{' '}
                            {getMonthAndYearDate(new Date(workflow.start_date))}
                          </Colxx>
                          <Colxx
                            xxs={{ size: 'auto', offset: 2 }}
                            md={{ size: 3 }}
                          >
                            {toCurrency(+iva_total)}
                          </Colxx>
                          <Colxx
                            xxs={{ size: 8, offset: 0 }}
                            style={{ fontSize: '1rem' }}
                            className="font-weight-bold mt-2"
                          >
                            <div>IVA a favor para el siguiente periodo</div>
                          </Colxx>
                          <Colxx xxs={{ size: 3, offset: 1 }} className="mt-2">
                            {toCurrency(nextPeriodIva)}
                          </Colxx>
                        </Row>
                      </section>
                      <hr />
                      <Row>
                        <Colxx
                          xxs={{ size: 8, offset: 0 }}
                          style={{ fontSize: '1rem' }}
                          className="font-weight-bold"
                        >
                          <div>Total a pagar de IVA</div>
                        </Colxx>
                        <Colxx
                          xxs={{ size: 3, offset: 1 }}
                          className="font-weight-bold"
                        >
                          {toCurrency(totalIvaToPay)}
                        </Colxx>
                      </Row>

                      <Row>
                        <Colxx
                          xxs={{ size: 8, offset: 0 }}
                          style={{ fontSize: '1rem' }}
                          className="font-weight-bold"
                        >
                          <div>Total a pagar de ISR</div>
                        </Colxx>
                        <Colxx
                          xxs={{ size: 3, offset: 1 }}
                          className="font-weight-bold"
                        >
                          {toCurrency(totalIsrToPay)}
                        </Colxx>
                      </Row>

                      <SalariesRetentionComputeResult
                        totalPayrollWithholdingsToPay={
                          +total_payroll_withholdings_to_pay
                        }
                        employmentSubsidyOfPayroll={
                          +employment_subsidy_of_payroll
                        }
                        taxesWithheldOfPayroll={+taxes_withheld_of_payroll}
                      />
                      <hr />
                      {totalLateFees > 0 && (
                        <>
                          <hr />
                          <Row>
                            <Colxx
                              xxs={{ size: 8, offset: 0 }}
                              style={{ fontSize: '1rem' }}
                              className="font-weight-bold"
                            >
                              <div>Recargos</div>
                            </Colxx>
                            <Colxx
                              xxs={{ size: 3, offset: 1 }}
                              className="font-weight-bold"
                            >
                              {toCurrency(totalLateFees)}
                            </Colxx>
                          </Row>
                          <hr />
                        </>
                      )}
                      <Row>
                        <Colxx
                          xxs={{ size: 8, offset: 0 }}
                          style={{ fontSize: '1rem' }}
                          className="font-weight-bold"
                        >
                          <div>Compensaciones</div>
                        </Colxx>
                        <Colxx
                          xxs={{ size: 3, offset: 1 }}
                          className="font-weight-bold"
                        >
                          {toCurrency(+undue_payment_iva_in_favor)}
                        </Colxx>
                        <hr />
                      </Row>
                      <Row>
                        <Colxx
                          xxs={{ size: 8, offset: 0 }}
                          style={{ fontSize: '1rem' }}
                          className="font-weight-bold"
                        >
                          <div>Total a pagar</div>
                        </Colxx>
                        <Colxx
                          xxs={{ size: 3, offset: 1 }}
                          className="font-weight-bold"
                        >
                          {toCurrency(totalToPay)}
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </div>
          </div>
        </WorkflowLayout>
      </Main>
    </Root>
  );
}

const mapStateToProps = ({ workflows }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  return { workflow, wloading };
};

const mapDispatchToProps = {
  workflowUpdate,
  workflowSetTransition,
  workflowPreviousPhase,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResult);
