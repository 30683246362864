import {
  PFAE_SAT_KEY,
  PF_SAT_KEY,
  RESICO_SAT_KEY,
  SALARIES_SAT_KEY,
} from 'shared/constants/grids';
import type { AnnualDeclaration } from 'types/entities';
import useRegimeAnnualResume from './useRegimeAnnualResume';
import RegimeCard from './RegimeCard/RegimeCard';
import { Content } from './styled';

interface RegimeAnnualResumeProps {
  onHandleResumeByRegime: (
    satKeys: number[],
    annualDeclaration: AnnualDeclaration,
  ) => void;
  isWorkflowsLoading?: boolean;
  currentAnnualDeclaration: AnnualDeclaration;
  period: number;
}
export default function RegimeAnnualResume({
  onHandleResumeByRegime,
  isWorkflowsLoading,
  currentAnnualDeclaration,
  period,
}: RegimeAnnualResumeProps) {
  const {
    declarationsByRegimen,
    hasResico,
    hasPfae,
    hasPlatforms,
    totals,
    isLoading,
    hasPfaeAndPlatforms,
    hasSalaries,
  } = useRegimeAnnualResume({ currentAnnualDeclaration, period });

  const pfaeAndPlatformsDeclaration = declarationsByRegimen.find(
    (declaration) => declaration.regimeSatKey === '612_625',
  );
  const platformsDeclaration = declarationsByRegimen.find(
    (declaration) => declaration.regimeSatKey === PF_SAT_KEY,
  );
  const pfaeDeclaration = declarationsByRegimen.find(
    (declaration) => declaration.regimeSatKey === PFAE_SAT_KEY,
  );
  const resicoDeclaration = declarationsByRegimen.find(
    (declaration) => declaration.regimeSatKey === RESICO_SAT_KEY,
  );
  const hasEmptyAnnualTotals =
    currentAnnualDeclaration.annual_declaration_totals.length === 0;

  return (
    <Content>
      {hasPfaeAndPlatforms && (
        <RegimeCard
          currentAnnualDeclaration={currentAnnualDeclaration}
          isLoading={isLoading || isWorkflowsLoading}
          regime="pfaeAndPlatforms"
          totals={totals}
          onDetailClick={() => {
            onHandleResumeByRegime(
              [PFAE_SAT_KEY, PF_SAT_KEY],
              currentAnnualDeclaration,
            );
          }}
          declarationsByRegimen={
            pfaeAndPlatformsDeclaration as Record<
              string,
              string | number | Record<string, number>
            >
          }
        />
      )}
      {!hasPfaeAndPlatforms && hasPfae && (
        <RegimeCard
          currentAnnualDeclaration={currentAnnualDeclaration}
          isLoading={isLoading || isWorkflowsLoading}
          regime="pfae"
          totals={totals}
          onDetailClick={() => {
            onHandleResumeByRegime([PFAE_SAT_KEY], currentAnnualDeclaration);
          }}
          declarationsByRegimen={
            pfaeDeclaration as Record<
              string,
              string | number | Record<string, number>
            >
          }
        />
      )}
      {!hasPfaeAndPlatforms && hasPlatforms && (
        <RegimeCard
          currentAnnualDeclaration={currentAnnualDeclaration}
          isLoading={isLoading || isWorkflowsLoading}
          regime="hasPlatforms"
          totals={totals}
          onDetailClick={() => {
            onHandleResumeByRegime([PF_SAT_KEY], currentAnnualDeclaration);
          }}
          declarationsByRegimen={
            platformsDeclaration as Record<
              string,
              string | number | Record<string, number>
            >
          }
        />
      )}
      {hasResico && (
        <RegimeCard
          currentAnnualDeclaration={currentAnnualDeclaration}
          regime="resico"
          totals={totals}
          onDetailClick={() => {
            onHandleResumeByRegime([RESICO_SAT_KEY], currentAnnualDeclaration);
          }}
          declarationsByRegimen={
            resicoDeclaration as Record<
              string,
              string | number | Record<string, number>
            >
          }
        />
      )}
      {hasSalaries &&
        !hasResico &&
        !hasPlatforms &&
        !hasPfae &&
        !hasPfaeAndPlatforms && (
          <RegimeCard
            currentAnnualDeclaration={currentAnnualDeclaration}
            regime="salaries"
            totals={totals}
            onDetailClick={() => {
              onHandleResumeByRegime(
                [SALARIES_SAT_KEY],
                currentAnnualDeclaration,
              );
            }}
          />
        )}
      {hasEmptyAnnualTotals && !hasResico && (
        <RegimeCard
          currentAnnualDeclaration={currentAnnualDeclaration}
          regime="empty"
          totals={totals}
        />
      )}
    </Content>
  );
}
