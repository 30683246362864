import type { TokenResponse } from '@react-oauth/google';
import type { ReactNode } from 'react';
import type { GroupDataPoint } from '@konta/ui/dist/components/BarSeries/BarSeries';
import { FileWithPath } from 'react-dropzone';

/* General interface for update request */
interface UpdatePayload<T> {
  id: number | string;
  payload: Partial<T>;
}

export type SomeAreRequired<T, K extends keyof T> = Required<Pick<T, K>> &
  Omit<T, K>;

type OneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type ZeroToNine = 0 | OneToNine;
type YYYY = `19${ZeroToNine}${ZeroToNine}` | `20${ZeroToNine}${ZeroToNine}`;
type MM = `0${OneToNine}` | `1${0 | 1 | 2}`;
type DD = `${0}${OneToNine}` | `${1 | 2}${ZeroToNine}` | `3${0 | 1}`;
export type DateStr = `${YYYY}-${MM}-${DD}`;

export interface DeclarationAssociation {
  id: number;
  fiscal_regime_key: number;
  start_date: string;
}
export type DiscountMethod = 'factor' | 'subtraction';
export interface Coupon {
  id: number;
  discount_method: DiscountMethod;
  amount: string;
  number_of_applications: number;
  active: boolean;
  created_at: DateStr;
  updated_at: DateStr;
  code: string;
  application_expiration_date: DateStr | null;
}

export interface ReactSelectItemProps<T> {
  value: T;
  key?: number | string;
  label: string | ReactNode;
  id?: number;
  subLabel?: string | ReactNode;
  uuid?: string;
}

export interface Invitation {
  status:
    | 'suggestion'
    | 'sent'
    | 'accepted'
    | 'onboarding'
    | 'completed'
    | 'rejected'
    | 'discarded';
  phone_number: string;
  id: number;
  email: string;
  created_at: string;
  updated_at: string;
  taxable_entity_id: number;
  rank: number;
  token: string;
}

// Referrer
export interface Referrer {
  id: number;
  taxable_entity_id: number;
  code: string;
  created_at: string;
  updated_at: string;
  coupon_id: number;
}
export type ReferrerCreatePayload = Pick<
  Referrer,
  'code' | 'taxable_entity_id' | 'coupon_id'
>;
export type ReferrerUpdatePayload = UpdatePayload<Referrer>;

// #region process types
export interface ProcessStepBase {
  id: number;
  onboarding_process_id: number;
  position: number;
  completed: boolean;
  name: string;
  description: string;
  validation_errors: string[];
}

export enum OnboardingProcessStepTypes {
  Welcome = 'WelcomeOnboardingProcessStep',
  Schedule = 'ScheduleOnboardingProcessStep',
  Meantime = 'MeantimeOnboardingProcessStep',
  Feedback = 'FeedbackOnboardingProcessStep',
  Referrals = 'ReferralsOnboardingProcessStep',
  Income = 'IncomeOnboardingProcessStep',
  Expense = 'ExpenseOnboardingProcessStep',
  Credentials = 'CredentialsOnboardingProcessStep',
}

export type WelcomeProcessStepType = OnboardingProcessStepTypes.Welcome;
export interface WelcomeProcessStep extends ProcessStepBase {
  type: WelcomeProcessStepType;
  answers: boolean;
}

export interface ScheduledDetail {
  end_time: DateStr | string;
  start_time: string;
  invitee_uuid: DateStr | string;
  accounter_email: DateStr | string;
  taxable_entity_id: number;
  scheduled_event_uuid: DateStr | string;
}

export type ScheduleProcessStepType = OnboardingProcessStepTypes.Schedule;
export interface ScheduleProcessStep extends ProcessStepBase {
  type: ScheduleProcessStepType;
  answers: ScheduledDetail | string;
}

export type MeanTimeAnswers = {
  tax_obligations: string[];
  income_source: string;
  salaried: boolean;
  correct_tax_address: boolean;
  tax_address: string;
  tax_regimes_to_keep?: string[];
  tax_regimes_to_remove?: string[];
  tax_regimes_to_add?: string[];
  tax_activities_to_remove?: string[];
  tax_activities_to_keep?: Record<string, number>;
  tax_activities_to_add?: Record<string, number>;
  procedure_date: DateStr | string;
  obligations_to_notify_withholding: boolean;
  has_delays: boolean;
  regularization_cost: number;
  wants_to_regularize_with_us: boolean;
  delays_preference: string;
  tax_arrears_generated: boolean;
  arrears_status: string;
  informed_client_of_current_state: boolean;
  informed_client_of_fpg: boolean;
  needs_to_be_in_list_of_importers: boolean;
  client_credentials: string[];
  has_sat_appointment: boolean;
  overall_client_attitude: string;
  call_notes: string;
  platforms_where_the_customer_works: string[];
  has_payroll: boolean;
};

export type MeantimeProcessStepType = OnboardingProcessStepTypes.Meantime;
export interface MeantimeProcessStep extends ProcessStepBase {
  type: MeantimeProcessStepType;
  answers: MeanTimeAnswers | string;
}

export type FeedbackProcessStepType = OnboardingProcessStepTypes.Feedback;
export interface FeedbackProcessStep extends ProcessStepBase {
  type: FeedbackProcessStepType;
  answers: string;
}

export type ReferralsProcessStepType = OnboardingProcessStepTypes.Referrals;
export interface ReferralsProcessStep extends ProcessStepBase {
  type: ReferralsProcessStepType;
  answers: string;
}

export type IncomeProcessStepType = OnboardingProcessStepTypes.Income;
export interface IncomeProcessStepAnswers {
  occupation: string[];
  investments: string[];
  has_investments: boolean;
  business_details: string[];
  has_foreign_income: boolean;
  business_description: string;
}
export interface IncomeProcessStep extends ProcessStepBase {
  type: IncomeProcessStepType;
  answers: IncomeProcessStepAnswers;
}

export type ExpenseProcessStepType = OnboardingProcessStepTypes.Expense;
export interface ExpenseProcessStep extends ProcessStepBase {
  type: ExpenseProcessStepType;
  answers: {
    common_expenses: string;
    has_foreign_products: boolean;
    signed_on_importer_list: boolean;
  };
}

export type CredentialsProcessStepType = OnboardingProcessStepTypes.Credentials;

export type CredentialsAnswer = {
  ciec: 'invalid' | 'valid' | 'error' | 'pending';
  fiel: boolean;
};
export interface CredentialsProcessStep extends ProcessStepBase {
  type: CredentialsProcessStepType;
  answers: CredentialsAnswer | 'invalid' | 'valid' | 'error' | 'pending';
}

export type OnboardingProcessStep =
  | WelcomeProcessStep
  | ScheduleProcessStep
  | MeantimeProcessStep
  | FeedbackProcessStep
  | ReferralsProcessStep
  | IncomeProcessStep
  | ExpenseProcessStep
  | CredentialsProcessStep;

export type OnboardingProcessStepType =
  | WelcomeProcessStepType
  | ScheduleProcessStepType
  | MeantimeProcessStepType
  | FeedbackProcessStepType
  | ReferralsProcessStepType
  | IncomeProcessStepType
  | ExpenseProcessStepType
  | CredentialsProcessStepType;

export enum FinishOnboardingProcessStepTypes {
  Updating = 'UpdatingAccountOnboardingProcessStep',
}
export type UpdatingOnboardingProcessStepType =
  FinishOnboardingProcessStepTypes.Updating;

export type UpdatingOnboardingProcessStepAnswers = {
  tax_regime_and_activities_changes: boolean;
  tax_address_change: boolean;
  simple_assignment: boolean;
  skip_payment: boolean;
  notes?: string | null;
  team_id: string;
  fiscal_period?: DateStr;
  declaration_period?: DateStr;
  workflow_template_id?: string;
};
export interface UpdatingOnboardingProcessStep extends ProcessStepBase {
  type: UpdatingOnboardingProcessStepType;
  answers: string | UpdatingOnboardingProcessStepAnswers;
}
export type FinishOnboardingProcessStep = UpdatingOnboardingProcessStep;

export type FinishOnboardingProcessStepType = UpdatingOnboardingProcessStepType;

export enum AnualProcessStepTypes {
  Welcome = 'WelcomeAnnualProcessStep',
  Charges = 'ChargesAnnualProcessStep',
  Documents = 'ForeignDocumentsAnnualProcessStep',
  Resume = 'ResumeAnnualProcessStep',
  Preferences = 'PreferencesAnnualProcessStep',
  Rejection = 'RejectionReasonsAnnualProcessStep',
  Recommendations = 'RecommendationsAnnualProcessStep',
  Upload = 'UploadDataAnnualProcessStep',
  Informative = 'InformativeDataAnnualProcessStep',
}

export type WelcomeAnnualProcessStepType = AnualProcessStepTypes.Welcome;
export interface WelcomeAnnualProcessStep extends ProcessStepBase {
  type: WelcomeAnnualProcessStepType;
  answers: { order_id: number };
}

export type ChargesAnnualProcessStepType = AnualProcessStepTypes.Charges;
export interface ChargesAnnualProcessStep extends ProcessStepBase {
  type: ChargesAnnualProcessStepType;
  answers: boolean;
}
export type ForeignDocumentsAnnualProcessStepType =
  AnualProcessStepTypes.Documents;
export interface ForeignDocumentsAnnualProcessStep extends ProcessStepBase {
  type: ForeignDocumentsAnnualProcessStepType;
  answers: boolean;
}

export type ResumeAnnualProcessStepType = AnualProcessStepTypes.Resume;
export interface ResumeAnnualProcessStep extends ProcessStepBase {
  type: ResumeAnnualProcessStepType;
  answers: boolean;
}

export type BalancePaymentType =
  | 'compensation'
  | 'return'
  | 'pay_now'
  | '3_month_payment'
  | '6_month_payment';
export type PreferencesAnnualProcessStepType =
  AnualProcessStepTypes.Preferences;
export interface PreferencesAnnualProcessStep extends ProcessStepBase {
  type: PreferencesAnnualProcessStepType;
  bank_statement?: {
    name: string;
    contents: FileWithPath | null;
  };
  bank_statement_resico?: {
    name: string;
    contents: File | null;
  };
  answers: {
    balance: BalancePaymentType | null;
    clabe?: string | null;
    bank_name?: string | null;
    balance_resico?: BalancePaymentType | null | '';
    clabe_resico?: string | null;
    bank_name_resico?: string | null;
  };
}

export type RejectionReasonsAnnualProcessStepType =
  AnualProcessStepTypes.Rejection;
export interface RejectionReasonsAnnualProcessStep extends ProcessStepBase {
  type: RejectionReasonsAnnualProcessStepType;
  answers: boolean;
}

export type RecommendationsAnnualProcessStepType =
  AnualProcessStepTypes.Recommendations;
export interface RecommendationsAnnualProcessStep extends ProcessStepBase {
  type: RecommendationsAnnualProcessStepType;
  answers: boolean;
}

export type UploadDataAnnualProcessStepAnswers = {
  is_data_uploaded: boolean;
  lock_status?: string;
  lock_substate?: string;
};
export type UploadDataAnnualProcessStepType = AnualProcessStepTypes.Upload;
export interface UploadDataAnnualProcessStep extends ProcessStepBase {
  type: UploadDataAnnualProcessStepType;
  answers: UploadDataAnnualProcessStepAnswers | string;
}

export type InformativeDataAnnualStepType = AnualProcessStepTypes.Informative;
export interface InformativeDataAnnualStep extends ProcessStepBase {
  type: InformativeDataAnnualStepType;
  answers: {
    vehicle_sale_amount: number;
    vehicle_sale_date?: DateStr | null;
    vehicle_purchased_amount?: number | null;
    vehicle_purchased_date?: DateStr | null;
    real_state_sale_amount: number;
    loans_amount: number;
    donations_amount: number;
    governmental_support_amount: number;
    rewards: number;
    inheritance: number;
    charged_viaticals: number;
    foreign_income_or_pending_taxes: string | null;
    income_received?: number | null;
    foreign_tax_paid?: number | null;
    foreign_tax_paid_dividends?: number | null;
    foreign_tax_credit?: number | null;
    total_accumulable_income?: number | null;
    taxpayer_id_foreign_country?: string | null;
    country_income_obtained?: string | null;
  };
}

export type AnualProcessStep =
  | WelcomeAnnualProcessStep
  | ChargesAnnualProcessStep
  | ForeignDocumentsAnnualProcessStep
  | ResumeAnnualProcessStep
  | PreferencesAnnualProcessStep
  | RejectionReasonsAnnualProcessStep
  | RecommendationsAnnualProcessStep
  | UploadDataAnnualProcessStep
  | InformativeDataAnnualStep;

export type AnualProcessStepType =
  | WelcomeAnnualProcessStepType
  | ChargesAnnualProcessStepType
  | ForeignDocumentsAnnualProcessStepType
  | ResumeAnnualProcessStepType
  | PreferencesAnnualProcessStepType
  | RejectionReasonsAnnualProcessStepType
  | RecommendationsAnnualProcessStepType
  | UploadDataAnnualProcessStepType
  | InformativeDataAnnualStepType;

// #endregion

export type ProcessStep =
  | OnboardingProcessStep
  | AnualProcessStep
  | FinishOnboardingProcessStep;
export type ProcessStepType =
  | OnboardingProcessStepType
  | AnualProcessStepType
  | FinishOnboardingProcessStepType;

// add more process types here
export enum ProcessType {
  OnboardingOnboardingProcess = 'OnboardingOnboardingProcess',
  FinishOnboardingProcess = 'FinishOnboardingProcess',
  AnnualDeclarationProcess = 'AnnualDeclarationProcess',
}

export interface OnboardingStep {
  type: ProcessType.OnboardingOnboardingProcess;
  welcome: WelcomeProcessStep;
  schedule: ScheduleProcessStep;
  meantime: MeantimeProcessStep;
  feedback: FeedbackProcessStep;
  referrals: ReferralsProcessStep;
  income: IncomeProcessStep;
  expense: ExpenseProcessStep;
  credentials: CredentialsProcessStep;
}
export interface FinishOnboardingStep {
  type: ProcessType.FinishOnboardingProcess;
  updating: UpdatingOnboardingProcessStep;
}

export interface AnnualStep {
  type: ProcessType.AnnualDeclarationProcess;
  welcome: WelcomeAnnualProcessStep;
  charges: ChargesAnnualProcessStep;
  documents: ForeignDocumentsAnnualProcessStep;
  resume: ResumeAnnualProcessStep;
  preferences: PreferencesAnnualProcessStep;
  reject: RejectionReasonsAnnualProcessStep;
  recommendations: RecommendationsAnnualProcessStep;
  upload: UploadDataAnnualProcessStep;
  informative: InformativeDataAnnualStep;
}

export type Steps = OnboardingStep | AnnualStep | FinishOnboardingStep;

export interface Process {
  id: number;
  position: number;
  current_step: ProcessStepType | null;
  completed: boolean;
  taxable_entity_id: number;
  type: ProcessType;
  name: string;
  description: string;
  steps: ProcessStep[];
  grouped_steps: Record<string, ProcessStep[]>;
  process_wrapper_id: number;
}

export interface AssignProcessWrapperTaskPayload {
  payload: object;
  taskId: number;
}
export interface Task {
  id: number;
  user_id: number;
  process_wrapper_id: number;
  action: 'preparing_customer' | 'onboarding_call' | 'onboarding_procedures';
  completed_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface ProcessWrapper {
  id: number;
  completed: boolean;
  taxable_entity_id: number;
  type: string;
  processes: Process[];
  tasks: Task[];
}

export type OnboardingProcess = Process & { step: OnboardingStep };
export type FinishOnboardingProcess = Process & { step: FinishOnboardingStep };

export interface OnboardingProcessWrapper
  extends Omit<ProcessWrapper, 'processes'> {
  processes: {
    OnboardingOnboardingProcess: OnboardingProcess;
    FinishOnboardingProcess: FinishOnboardingProcess;
  };
}

export interface FiscalRegime {
  id: number;
  description: string;
  sat_key: number;
  alias?: string | null;
  start_date: string | null;
  end_date: string | null;
}

export interface TaxStatus {
  id: number;
  file_url?: string;
  file_type?: string;
  extension?: string;
}

export interface OnboardingDetail {
  id: number;
  intro_video: boolean;
  taxable_entity_id: number;
  status?: string | null;
  has_booked_call: boolean;
  is_rejected_user: boolean;
}
/* WORKFLOW TEMPLATES */
export type WorkflowTemplateName =
  | 'plataformas'
  | 'resico'
  | 'pfae'
  | 'pfae y plataformas';
export type WorkflowTemplateModelName =
  | 'pfae'
  | 'platforms'
  | 'resico'
  | 'pfae_and_pt';
export interface WorkflowTemplate {
  id: number;
  name: WorkflowTemplateName;
  income_upload_way: string | null;
  iva_rate: number | null;
  workflow_template_id: number;
}
export interface Obligation {
  id: number;
  obligation_name: string;
  created_at: string;
  updated_at: string | null;
  start_date: string | null;
  end_date: string | null;
  due_date: string | null;
  deleted_at: string | null;
  fiscal_regime_id: number | null;
  name: string;
}

export interface TaxableEntityFiscalRegime {
  id: number;
  taxable_entity_id: number;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
  fiscal_regime_id: number;
  start_date: string | null;
  end_date: string | null;
  last_updated: string | null;
  preferred: boolean;
  fiscal_regime: FiscalRegime;
}

export interface EconomicActivity {
  id: number;
  economic_activity_name: string;
  created_at: string;
  updated_at: string;
  order: number;
  start_date: string | null;
  end_date: string | null;
  percentage: number;
  deleted_at: string | null;
}
export interface TaxableEntity {
  uuid?: string;
  id: number;
  legal_name?: string | null;
  legal_type?: string | null;
  rfc?: string | null;
  legal_first_name?: string | null;
  legal_last_name?: string | null;
  legal_email: string | null;
  curp?: string | null;
  valid_credentials?: boolean | null;
  has_csd: boolean;
  has_ciec: boolean;
  has_fiel: boolean;
  address: number;
  current_fiscal_period: number;
  status: string;
  has_payroll_credentials: boolean;
  description?: string | null;
  phone_number?: string | null;
  invoicing_rfc?: string | null;
  invoicing_legal_name?: string | null;
  referal?: string | null;
  backoffice_description?: string | null;
  fiscal_regimes: FiscalRegime[];
  onboarding_detail?: OnboardingDetail;
  arrears_status: string;
  taxable_entities_obligations: Obligation[];
  logo_url?: string | null;
  show_logo_in_invoice?: boolean | null;
  pending_rewards_count: number;
  rewards_count: number;
  reward_type: string;
  sat_status?: string | null;
  postcode?: string | null;
  workflow_setting?: WorkflowTemplate | null;
  logo?: null;
  user?: User;
  team?: string;
  team_id?: number;
  taxable_entity_economic_activities?: EconomicActivity[];
  taxable_entity_fiscal_regimes?: TaxableEntityFiscalRegime[];
  preferred_invoice_fiscal_regime_key?: string | number | null;
}
export type TaxableEntityPayload = {
  taxable_entity:
    | Partial<TaxableEntity>
    | {
        workflow_setting_attributes: Partial<WorkflowTemplate>;
      }
    | {
        fiscal_regime_ids: number[];
      }
    | {
        onboarding_detail_attributes: Partial<OnboardingDetail>;
      };
};

export interface TaxableEntityUpdateRequest {
  taxableEntityId: number;
  payload: TaxableEntityPayload;
}
export interface NotifyDeclarationRequest {
  taxableEntityId: number;
  payload: Record<string, string[]>;
}
export interface NotifyDeclarationRequestResponse {
  message: string;
}
export type NotifyAboutPresentedDeclarationRequest = NotifyDeclarationRequest;
export type NotifyAboutPresentedDeclarationResponse =
  NotifyDeclarationRequestResponse;
export type NotifyAboutPendingApproveDeclarationRequest =
  NotifyDeclarationRequest;
export type NotifyAboutPendingApproveDeclarationResponse =
  NotifyDeclarationRequestResponse;

export interface TaxableEntityPreferredFiscalRegimePatchRequest {
  taxableEntityId: number;
  payload: {
    sat_key: number;
  };
}

export interface TaxableEntityCredentials {
  valid_credentials: boolean | null;
  key_url: string;
  cer_url: string;
  payroll_key_url: string;
  payroll_cer_url: string;
  ciec: string;
  csd_cer_url: string;
  csd_key_url: string;
  csd_password?: string;
  fiel_password?: string;
  fiel_expiration_date: DateStr;
  csd_expiration_date: DateStr;
  onboarding_detail: OnboardingDetail;
}
export interface TaxableEntityCoupon {
  id: number;
  taxable_entity_id: number;
  coupon_id: number;
  giver_id: number;
  coupon: Coupon;
}

export type TaxableEntityPublic = Pick<
  TaxableEntity,
  'legal_name' | 'logo_url'
>;

type Role =
  | 'admin'
  | 'customer_success_admin'
  | 'customer_success'
  | 'accountant_admin'
  | 'accountant'
  | 'accountant_assistant'
  | 'accountant_practitioner'
  | 'account_holder_admin'
  | 'account_holder_assistant'
  | 'sales'
  | 'sales_admin';

export interface User {
  id: number;
  access_token: string;
  email: string;
  first_name: string;
  nickname: string;
  phone_number: string;
  role: Role;
  otp_required_for_login?: boolean | null;
  otp_secret?: string | null;
  taxable_entity: TaxableEntity;
  otp_token?: string | null;
  password?: string | null;
}

export type ProductType = 'Declaration' | 'TaxArrear' | 'Complementary';

export interface TaxArrear {
  id: number;
  total: number;
  discount: number;
  valid_to: DateStr;
  date_paid?: string | null;
  number_of_months: number;
  status: 'pending_payment' | 'paid';
  taxable_entity_id: number;
  order_id: number;
  product_type: ProductType;
  arrear_months: DateStr[];
  created_at: DateStr;
  synced_with_airtable_at: DateStr;
}

export interface TaxArrearPayload {
  tax_arrear: Pick<
    TaxArrear,
    'total' | 'discount' | 'product_type' | 'arrear_months'
  >;
}

/* Fiscal Period */
export interface FiscalPeriod {
  id: number;
  start_date: DateStr;
  end_date: DateStr;
  deducible_expenses?: number;
  taxable_income?: number;
  iva_total?: number;
  isr_total?: number;
  capture_line?: number;
  status?: number;
  taxable_entity_id?: number;
}
export interface FiscalPeriodPayload {
  fiscal_period: Partial<FiscalPeriod>;
}

export type SubscriptionStatus =
  | 'pending_payment'
  | 'cancellation_in_progress'
  | 'active'
  | 'inactive'
  | 'incomplete' // 6 - Cotización
  | 'cancelled';
export interface Service {
  id: number;
  name: 'standard_plan' | 'starter_plan' | 'started_plan';
  price: number;
  service_type: string;
  base: boolean;
  legal_types: number[];
}
export type ServiceSubscriptionDurationMonths = 1 | 6 | 12;
export interface ServiceSubscription {
  id: number;
  subscription_id: number;
  service_id: number;
  created_at: DateStr;
  updated_at: DateStr;
  price: string;
  discount: string;
  quantity: number;
  total: string;
  valid_to: DateStr;
  date_subscribed: DateStr;
  date_unsubscribed?: DateStr | null;
  duration_months: ServiceSubscriptionDurationMonths;
  status: string;
  base: boolean;
  service: Service;
}
export interface CurrentSubscriptionRequest {
  id: number;
  date_subscribed: DateStr;
  total: string;
  status: SubscriptionStatus;
  current_service_subscriptions: ServiceSubscription[];
  next_service_subscriptions: ServiceSubscription[];
  taxable_entity_coupon: null | TaxableEntityCoupon;
}

export interface SubscriptionActiveUpdatePayload {
  subscriptionId: number;
  payload?: undefined;
}
export interface SubscriptionSupendPayload {
  subscriptionId: number;
  payload: {
    reason: {
      cancellation_reason: string[] | [];
    };
    comments: string | null;
  };
}
export type SubscriptionCancelPayload = SubscriptionActiveUpdatePayload;
export type QuotationDeletePayload = SubscriptionActiveUpdatePayload;

type ServiceSubscriptionAttributes = Pick<
  ServiceSubscription,
  'service_id' | 'quantity' | 'duration_months'
>;

export interface SubscriptionCreatePayload {
  subscriptions: {
    service_subscriptions_attributes: ServiceSubscriptionAttributes[];
    subscription_type: string;
  }[];
  payment_way: string | null;
  discountCode?: string;
}

export type SubscriptionUpdatePayload = {
  subscriptionId: number;
  payload: Pick<SubscriptionCreatePayload, 'subscriptions'>;
};

export type ServiceSubscriptionType = {
  service_id: number;
  quantity?: number;
  duration_months?: number;
  valid_to?: string;
  date_subscribed?: string;
  discount?: number;
  price?: number;
  total?: number;
};

export type SubscriptionAddServicePayload = {
  subscriptionId: number;
  payload: {
    service_subscriptions_attributes: ServiceSubscriptionType[];
    service_subscription_reference_id: number;
  };
};

export interface OrderBilling {
  id: number;
  order_id: number;
  balance: number;
  total_paid: number;
  total_refund: number;
  billing: {
    id: number;
    amount: number;
    payment_status: string;
    charge_id: string;
    currency: string;
    conekta_order_id: string;
    created_at: DateStr;
    updated_at: DateStr;
    payment_method_id: number;
    taxable_entity_id: number;
    balance: number;
    total_refund: number;
    total_paid: number;
    paid_at: DateStr;
  };
}

export interface TaxOrder {
  id: number;
  balance: number;
  total: number;
  discount: number;
  total_paid: number;
  payment_status: string;
  due_amount: number;
  total_refund: number;
  date_paid?: string | null;
  created_by_id?: number | null;
  valid_to: DateStr;
  source_type: string;
  number_of_months: number;
  status: string;
  taxable_entity_id: number;
  order_id: number;
  product_type: ProductType;
  arrear_months: DateStr[];
  tax_arrears: TaxArrear[];
  subscriptions: ServiceSubscription[];
  order_billings: OrderBilling[];
  taxable_entity: {
    id: number;
    status: string;
  };
}

export type AnnualDeclarationTransactionType =
  | 'income'
  | 'income_exempt'
  | 'income_taxable'
  | 'expense'
  | 'tax_retained'
  | 'provisional_payment'
  | 'personal_deduction'
  | 'tax_losses'
  | 'compensation'
  | 'fixed_asset';
export type AnnualDeclarationSourceType =
  | 'salary'
  | 'business_activity'
  | 'digital_platform'
  | 'dividend'
  | 'interest'
  | 'resico';
export interface AnnualDeclarationTotal {
  id: number;
  annual_declaration_id: number;
  transaction_type: AnnualDeclarationTransactionType;
  source_type: AnnualDeclarationSourceType;
  amount: string;
  created_by: 'konta_app' | 'retool_app';
}
export interface AnnualDeclaration {
  id: number;
  taxable_entity_id: number;
  total: number;
  status: string;
  period: number;
  annual_rate: number;
  profit_or_loss: string;
  isr_tariff: string;
  profit_or_loss_resico: string;
  questions_and_answers: string;
  fiscal_regime: FiscalRegime | null;
  annual_declaration_totals: AnnualDeclarationTotal[];
  fiscal_regime_id: number;
  tax_loss: string;
  acuses: string[];
}

export interface PaymentMethod {
  id: number;
  taxable_entity_id: number;
  source_type: string;
  brand: string;
  card_subscription_id: string;
  card_number: string;
  holder_name: string;
  exp_year: string;
  exp_month: string;
  bank_name: string;
  bank_code: string;
  active: boolean;
  created_at: DateStr;
  updated_at: DateStr;
  reference_subscription: string;
  default_card: boolean;
  card_taxes_id: string;
  deleted_at: DateStr;
  conekta_subscription_customer_id: string;
  conekta_taxes_customer_id: string;
  reference_taxes: string;
  payment_method_type: string;
}

export type PaymentStatus = 'pending' | 'paid';

export type SourceOfIncome =
  | 'manual_input'
  | 'bank_transaction'
  | 'invoice'
  | 'constancy';

export interface DeclarationIncome {
  id: number;
  declaration_id: number;
  total: string;
  total_unbilled: string;
  total_iva_bases_unbilled: string;
  total_exempt_bases_unbilled: string;
  total_iva_bases: string;
  total_exempt_bases: string;
  total_iva: string;
  total_retained_iva: string;
  total_retained_isr: string;
  total_bases_of_extra: string;
  extra_iva_bases: string;
  extra_exempt_bases: string;
  extra_iva: string;
  extra_iva_bases8: string;
  extra_iva_rate: string;
  sum_iva_bases_of_active_cfdis: string;
  sum_exempt_bases_of_active_cfdis: string;
  sum_iva_of_active_cfdis: string;
  sum_retained_iva_of_active_cfdis: string;
  sum_retained_isr_of_active_cfdis: string;
  sum_iva_bases_of_cancelled_cfdis: string;
  sum_exempt_bases_of_cancelled_cfdis: string;
  sum_iva_of_cancelled_cfdis: string;
  sum_retained_iva_of_cancelled_cfdis: string;
  sum_retained_isr_of_cancelled_cfdis: string;
  sum_iva_bases_of_egress: string;
  sum_exempt_bases_of_egress: string;
  sum_iva_of_egress: string;
  sum_retained_iva_of_egress: string;
  sum_retained_isr_of_egress: string;
  sum_iva_bases_of_cancelled_egress: string;
  sum_exempt_bases_of_cancelled_egress: string;
  sum_iva_of_cancelled_egress: string;
  sum_retained_iva_of_cancelled_egress: string;
  sum_retained_isr_of_cancelled_egress: string;
  sum_iva_bases_of_fixed_assets: string;
  sum_exempt_bases_of_fixed_assets: string;
  sum_iva_of_fixed_assets: string;
  sum_retained_iva_of_fixed_assets: string;
  sum_retained_isr_of_fixed_assets: string;
  total_of_constancies: string;
  sum_iva_bases_of_constancies: string;
  sum_exempt_bases_of_constancies: string;
  sum_retained_iva_of_constancies: string;
  sum_transferred_iva_of_constancies: string;
  sum_retained_isr_of_constancies: string;
  sum_iva_rate_of_constancies: string;
  total_of_bank_transactions: string;
  sum_iva_bases_of_bank_transactions: string;
  sum_exempt_bases_of_bank_transactions: string;
  sum_iva_of_bank_transactions: string;
  total_of_cfdis: string;
  total_iva_bases_of_cfdis: string;
  total_iva_exempt_bases_of_cfdis: string;
  total_iva_of_cfdis: string;
  total_retained_iva_of_cfdis: string;
  total_retained_isr_of_cfdis: string;
  total_of_manual_input: string;
  total_iva_bases_of_manual_input: string;
  total_exempt_bases_of_manual_input: string;
  total_iva_of_manual_input: string;
  total_of_extras: string;
  is_manual_input: boolean;
  total_retained_iva_of_manual_input: string;
  total_retained_isr_of_manual_input: string;
  total_with_iva_of_manual_input: string;
  sum_iva_bases_of_payment_cfdis: string;
  sum_exempt_bases_of_payment_cfdis: string;
  sum_iva_of_payment_cfdis: string;
  sum_retained_iva_of_payment_cfdis: string;
  sum_retained_isr_of_payment_cfdis: string;
  total_zero_bases_unbilled: string;
  sum_zero_bases_of_active_cfdis: string;
  sum_zero_bases_of_cancelled_cfdis: string;
  sum_zero_bases_of_egress: string;
  sum_zero_bases_of_bank_transactions: string;
  total_zero_bases_of_cfdis: string;
  extra_zero_bases: string;
  total_zero_bases_of_manual_input: string;
  sum_zero_bases_of_payment_cfdis: string;
  total_zero_bases: string;
  total_bases_of_outdated_cfdis: string;
  accrued_income: string;
  total_prev_bases_of_outdated_cfdis: string;
  total_with_iva16_of_manual_input: string;
  total_with_iva8_of_manual_input: string;
  accrued_isr_retention: string;
  total_iva_base16_unbilled: string;
  total_iva_base8_unbilled: string;
  total_iva8_bases: string;
  total_iva16_bases: string;
  taxes_withheld_of_payroll: number | string;
  employment_subsidy_of_payroll: number | string;
  total_retained_isr8_of_cfdis: string;
  total_retained_iva8_of_cfdis: string;
  total_retained_isr16_of_cfdis: string;
  total_retained_iva16_of_cfdis: string;
  sum_iva_base8_of_manual_input: string;
  sum_iva_base8_of_payment_cfdis: string;
  sum_iva_base8_of_active_cfdis: string;
  sum_iva_base8_of_bank_transactions: string;
  sum_iva_base8_of_cancelled_cfdis: string;
  sum_iva_base8_of_cancelled_egress: string;
  sum_iva_base8_of_cfdis: string;
  sum_iva_base8_of_egress: string;
  sum_iva_base8_of_fixed_assets: string;
  sum_iva_base16_of_active_cfdis: string;
  sum_iva_base16_of_bank_transactions: string;
  sum_iva_base16_of_cancelled_cfdis: string;
  sum_iva_base16_of_cancelled_egress: string;
  sum_iva_base16_of_cfdis: string;
  sum_iva_base16_of_egress: string;
  sum_iva_base16_of_manual_input: string;
  sum_iva_base16_of_payment_cfdis: string;
  source_of_income: SourceOfIncome;
  sum_iva16_amount_of_manual_input: string;
  sum_iva8_amount_of_manual_input: string;
  extra_iva_bases16: string;
  sum_iva16_amount_of_active_cfdis: string;
  sum_iva8_amount_of_active_cfdis: string;
}

export interface DeclarationExpense {
  id: number;
  declaration_id: number;
  total: string;
  total_iva_bases: string;
  total_exempt_bases: string;
  total_iva: string;
  total_retained_iva: string;
  total_retained_isr: string;
  total_retained_other: string;
  total_iva_bases_of_foreign_products: string;
  total_iva_of_foreign_products: string;
  total_bases_of_foreign_invoices: string;
  sum_iva_bases_of_active_cfdis: string;
  sum_exempt_bases_of_active_cfdis: string;
  sum_iva_of_active_cfdis: string;
  sum_retained_isr_of_active_cfdis: string;
  sum_retained_iva_of_active_cfdis: string;
  sum_iva_bases_of_cancelled_cfdis: string;
  sum_exempt_bases_of_cancelled_cfdis: string;
  sum_iva_of_cancelled_cfdis: string;
  sum_retained_iva_of_cancelled_cfdis: string;
  sum_retained_isr_of_cancelled_cfdis: string;
  sum_iva_bases_of_egress: string;
  sum_exempt_bases_of_egress: string;
  sum_iva_of_egress: string;
  sum_retained_iva_of_egress: string;
  sum_retained_isr_of_egress: string;
  sum_iva_bases_of_cancelled_egress: string;
  sum_exempt_bases_of_cancelled_egress: string;
  sum_iva_of_cancelled_egress: string;
  sum_retained_iva_of_cancelled_egress: string;
  sum_retained_isr_of_cancelled_egress: string;
  sum_iva_bases_of_fixed_assets: string;
  sum_exempt_bases_of_fixed_assets: string;
  sum_iva_of_fixed_assets: string;
  sum_retained_iva_of_fixed_assets: string;
  sum_retained_isr_of_fixed_assets: string;
  total_of_bank_transactions: string;
  sum_iva_bases_of_bank_transactions: string;
  sum_exempt_bases_of_bank_transactions: string;
  sum_iva_of_bank_transactions: string;
  sum_zero_bases_of_active_cfdis: string;
  sum_zero_bases_of_cancelled_cfdis: string;
  sum_zero_bases_of_egress: string;
  sum_zero_bases_of_bank_transactions: string;
  accreditable_iva_average: string;
  accreditable_isr_average: string;
  total_zero_bases: string;
  non_accreditable_iva: string;
  accrued_expense: string;
  total_iva_of_foreign_invoices: string;
  foreign_products_non_taxable_amount_total: string;
  sum_ieps_of_active_cfdis: string;
  sum_ieps_of_egress: string;
}

// general type for declaration_entry_type
export type GeneralDeclarationEntryType = 'income' | 'expenses';

// type for declaration_entry_type income
export type DeclarationEntryIncomeType =
  | 'foreign_income'
  | 'national_income'
  | 'national_egress_issued'
  | 'pastmonths_cancelled_issued'
  | 'pastmonths_egress_cancelled_issued'
  | 'national_income_payment';
// type for declaration_entry_type expenses
export type DeclarationEntryExpenseType =
  | 'foreign_expenses'
  | 'national_expenses'
  | 'foreign_products'
  | 'foreign_invoices'
  | 'national_egress_received'
  | 'pastmonths_cancelled_received'
  | 'pastmonths_egress_cancelled_received'
  | 'national_expenses_payment';

export type DeclarationEntryType =
  | DeclarationEntryIncomeType
  | DeclarationEntryExpenseType;

export interface ClientSupplier {
  id: number;
  legal_name: string | null;
  alias: string | null;
  rfc: string | null;
  email: string | null;
  relationship_type: string;
  taxable_entity_id: number;
  first_name: string | null;
  last_name: string | null;
  fiscal_regimes: FiscalRegime[];
  address: Address;
  cfdi_use_key: string | null;
  payment_form_key: string | null;
  source: string;
  fiscal_regime_ids?: number[];
  address_attributes?: Partial<Address>;
}
export interface PaymentForm {
  id: number;
  name: string;
  key: string;
  other_details: string | null;
}

export interface CfdiUsage {
  id: number;
  name: string;
  other_details: string | null;
  key: string;
  created_at: DateStr;
  updated_at: DateStr;
}
export interface ForeignTaxpayer {
  id: number;
  social_reason: string | null;
  country: string | null;
  registration_date: DateStr;
  status: 'active' | 'inactive';
}
export type SourceType =
  | 'cfdi'
  | 'cancelled_cfdi'
  | 'foreign_product'
  | 'foreign_invoice'
  | 'fixed_asset'
  | 'constancy'
  | 'bank_transaction'
  | 'payment_detail'
  | 'extra_income';

export type EntrySourceType =
  | 'Cfdi'
  | 'CancelledCfdi'
  | 'ForeignProduct'
  | 'ForeignInvoice'
  | 'FixedAsset'
  | 'Constancy'
  | 'BankTransaction'
  | 'PaymentDetail'
  | 'ExtraIncome';
export type CfdiStatus =
  | 'pending'
  | 'stamped'
  | 'acceptance_pending'
  | 'not_accepted'
  | 'cancelled'
  | 'cancellation_in_progress';
export type CfdiInvoiceType =
  | 'income'
  | 'expenses'
  | 'payment'
  | 'payroll'
  | 'transfer';
export interface Cfdi {
  id: number;
  client_supplier: ClientSupplier;
  invoice_type: CfdiInvoiceType;
  total: number;
  iva: string;
  retained_isr: string;
  retained_iva: string;
  ieps: number;
  date_issued: DateStr;
  is_pue: boolean;
  is_paid: string | null;
  discount: string | null;
  currency: string;
  status: CfdiStatus;
  is_emited: boolean;
  folio_fiscal: string;
  folio_ui: string | null;
  cfdiable_type: string;
  cfdiable_id: number;
  payment_form_id: number;
  cfdi_use_id: string | null;
  payment_method: string;
  exchange_rate: string | null;
  comments: string | null;
  subtotal: number;
  cfdi_id: string | null;
  xml_url: string | null;
  pdf_url: string | null;
  cancellation_xml_url: string;
  acceptance_url: string | null;
  issuer_rfc: string;
  receiver_rfc: string;
  issuer_legal_name: string;
  receiver_legal_name: string;
  banxico_exchange_rate: string;
  iva_0_percent: number;
  iva_8_percent: number;
  iva_16_percent: number;
  iva_exempt: number;
  concept_descriptions: string;
  payment_form: PaymentForm;
  cfdi_use: CfdiUsage;
  creditable_tax: string;
  retained_isr_base_exempt: string;
  retained_isr_base0: string;
  retained_isr_base8: string;
  retained_isr_base16: string;
  retained_iva_base_exempt: string;
  retained_iva_base0: string;
  retained_iva_base8: string;
  retained_iva_base16: string;
  declaration_entry_payments?: PaymentDetail[];
  payment_details?: PaymentDetail[];
  declarations?: DeclarationAssociation[];
  receiver_fiscal_regime: string;
  issuer_fiscal_regime: string;
}

export interface PaymentDetail {
  id: number;
  cfdi_id: number;
  ppd_id: number;
  uuid: string;
  payment_date: string;
  amount_paid: string;
  created_at: string;
  updated_at: string;
  banxico_exchange_rate: string;
  currency: string | null;
  total_with_iva: string;
  total_exempt: string;
  partiality_number: number;
  payment_form: string;
  previous_debt: string;
  new_debt: string;
  iva_base16: string;
  iva_base8: string;
  total_zero: string;
  iva16_amount: string;
  iva8_amount: string;
  retained_iva: string;
  retained_iva_base8: string;
  retained_iva_base16: string;
  retained_iva_base0: string;
  retained_iva_base_exempt: string;
  retained_isr: string;
  retained_isr_base8: string;
  retained_isr_base16: string;
  retained_isr_base0: string;
  retained_isr_base_exempt: string;
  creditable_tax: string;
  cfdi: Cfdi;
  declarations?: DeclarationAssociation[];
  source: {
    payment_detail: PaymentDetail;
  };
}
export interface CancelledCfdi {
  id: number;
  cancellation_date: DateStr;
  cfdi: Cfdi;
}
export interface FixedAsset {
  id: number;
  taxable_entity_id: number;
  amount: string;
  exchange_rate: string;
  depreciation_start_date: DateStr | null;
  depreciation_end_date: DateStr | null;
  monthly_depreciation: string;
  iva: string;
  cfdi: Cfdi;
  declarations?: DeclarationAssociation[];
}
export interface ForeignProduct {
  id: number;
  customs_value: string;
  taxable_amount: string;
  creditable_iva: string;
  non_taxable_amount: string;
  dta: string;
  igi: string;
  prv: string;
  iva: string;
  iva_prv: string;
  period: string;
  created_at: DateStr;
  // Link of s3 file
  documents: string | null;
  declarations?: DeclarationAssociation[];
  declaration_entries?: DeclarationEntry[];
}
export interface ForeignInvoice {
  id: number;
  amount: string;
  // Link of s3 file
  document: string | null;
  iva: string;
  period: string;
  document_url: string | null;
  foreign_taxpayer?: ForeignTaxpayer;
  declarations?: DeclarationAssociation[];
  declaration_entries?: DeclarationEntry[];
}
export interface DigitalPlatform {
  id: number;
  social_reason: string;
  is_constancy_required: boolean;
  rfcs: string[];
}
export interface Constancy {
  id: number;
  social_reason: string;
  digital_platforms_taxable_entity_id: number;
  total_base_exempt: string;
  total_base_iva: string;
  total_income: string;
  total_isr_retained: string;
  total_iva_retained: string;
  total_iva_transferred: string;
  digital_platform?: DigitalPlatform;
  declarations?: DeclarationAssociation[];
  start_month: string;
  uuid: string;
}

export interface BankTransaction {
  id: number;
  amount: string;
}

export interface ExtraIncome {
  id: number;
  taxable_entity_id: number;
  description: string;
  total: string;
  total8: string;
  total16: string;
  iva_base8: string;
  iva_base16: string;
  iva8: string;
  iva16: string;
  exempt_base: string;
  zero_base: string;
}

export type AccountingStatus =
  | 'is_deductible'
  | 'not_deductible'
  | 'not_paid'
  | 'paid'
  | 'fixed_asset'
  | 'annual'
  | 'cancelled'
  | 'pending_classification'
  | 'in_review';

export type Source = {
  type: SourceType;
  cfdi?: Cfdi;
  payment_detail?: PaymentDetail;
  cancelled_cfdi?: CancelledCfdi;
  fixed_asset?: FixedAsset;
  foreign_product?: ForeignProduct;
  foreign_invoice?: ForeignInvoice;
  constancy?: Constancy;
  bank_transaction?: BankTransaction;
  extra_income?: ExtraIncome;
};

export interface ChangesLog {
  author: 'Contador' | 'Usuario' | 'Sistema' | 'AI';
  changed_at: string;
  changes: Record<string, string[]>;
  parsed_changes: {
    attribute: string;
    old_value: string;
    new_value: string;
  }[];
}

export interface DeclarationEntry {
  id: number;
  declaration_entry_type: DeclarationEntryType;
  declaration_id: number;
  description: string;
  user_comments?: string;
  comments?: string;
  accounting_date?: DateStr;
  accounting_status: AccountingStatus;
  last_updater: 'Contador' | 'Usuario' | 'Sistema' | 'AI';
  taxable_entity_id: number;
  is_customer_update: boolean;
  source_type: EntrySourceType;
  source_id: number;
  fiscal_regime_id: number;
  accountant_id?: number;
  social_reason?: string;
  file?: string;
  source: Source;
  // TODO add correct type of categories
  expense_category: string | null;
  follow_up: string | null;
  transaction_category: string | null;
  argument: string | null;
  changes_log: ChangesLog[];
  created_at: string;
  fiscal_regime: FiscalRegime;
}
export interface DeclarationEntryUpdatePayload {
  declarationEntryId: number | string;
  payload?: FormData;
}

export interface GroupedEntries {
  issuer_legal_name: string;
  total: number;
  rfc: string;
}

export type SourceAttributes =
  | PaymentDetail
  | CancelledCfdi
  | FixedAsset
  | ForeignProduct
  | ForeignInvoice
  | Constancy
  | BankTransaction
  | null;

export type DeclarationDocumentAttributes = {
  attachment: string | File | FileWithPath;
  category_id: number;
  name: string;
  taxable_entity_id: number;
};

export interface DeclarationEntryPayload {
  declaration_entries: (DeclarationEntry & {
    source_attributes: SourceAttributes;
  })[];
}

export type CaptureLineDocument = {
  validationDate: string;
  captureLine: string;
  documentUrl: string;
  regimeName: string;
  isValid: boolean;
  hasNoDebt: boolean;
  hasDebt: boolean;
  totalToPay: number;
  paymentLimitDate: string;
};
export type DeclarationStatus = 'closed' | 'active';
export type DeclarationSource = 'retool' | 'satws';
export type DeclarationType = 'normal' | 'complementary';
export type DeclarationDocument = {
  capture_line: string;
  validation_date: DateStr;
  upload_date: DateStr;
  document?: string;
  salary_capture_line?: string;
  salary_capture_line_request_date?: DateStr;
  salary_document?: string;
  salary_validation_date?: DateStr;
  salary_updated_at?: DateStr;
  updated_at?: DateStr;
  document_attributes?: DeclarationDocumentAttributes;
  salary_document_attributes?: DeclarationDocumentAttributes;
};

export type DeclarationPayment = {
  id: number;
  declaration_id: number;
  payment_method_id: number;
  payment_date?: DateStr;
  conekta_order_id?: string;
  total_paid: string;
  created_at: DateStr;
  updated_at: DateStr;
};
export type DeclarationTransactionBalanceType = 'refund' | 'payment';
export type DeclarationTransactionType =
  | 'iva'
  | 'isr'
  | 'undue_payment_iva'
  | 'undue_payment_isr'
  | 'isr_tax_loss';

export type DeclarationPayer = {
  id: number;
  declaration_id: number;
  declaration_consumer_id: number;
  declaration_consumer: Declaration;
  declaration_balance_type: DeclarationTransactionBalanceType;
  amount: string;
  transaction_type: DeclarationTransactionType;
  payable_id: number;
  payable_type: string;
  payable: {
    type: string;
    declaration_balance: DeclarationBalance;
  };
};

export type PresentationAttemptStatus = 'pending' | 'in_progress' | 'done';
export type PresentationErrorCode =
  | 'iva_in_favor_greater_than_declared_iva'
  | 'no_compensations_section'
  | 'no_concepts_to_compensate'
  | 'no_iva_8_input'
  | 'no_iva_in_favor_input'
  | 'no_incentives_section'
  | 'no_income_to_decrease_section'
  | 'not_implemented'
  | 'obligations_mismatch'
  | 'out_of_resico'
  | 'unexpected_isr_to_pay'
  | 'unexpected_iva_to_pay'
  | 'wrong_credentials';
export interface PresentationAttempt {
  status: PresentationAttemptStatus;
  error_code: PresentationErrorCode;
  error_message: string;
  id: number;
  declaration_id: number;
}
export interface Declaration {
  id: number;
  workflow_id: number;
  taxable_entity_id: number;
  start_date: string;
  end_date: string;
  total_to_pay: string;
  iva_total: string;
  isr_total: string;
  status: DeclarationStatus;
  payment_status?: PaymentStatus;
  is_replaced: boolean;
  is_active: boolean;
  total_income: string;
  total_expenses: string;
  fiscal_regime_id: number;
  fiscal_regime: FiscalRegime;
  iva_rate: string;
  total_paid: string;
  iva_in_favor: string;
  iva_to_pay: string;
  isr_to_pay: string;
  iva_available: string;
  isr_available: string;
  invoiced_at: DateStr;
  isr_in_favor: string;
  accrued_payment: string;
  isr_tax_loss: string;
  declaration_income: DeclarationIncome;
  declaration_expense: DeclarationExpense;
  declaration_entries: DeclarationEntry[];
  operation_number?: string;
  operated_at?: DateStr;
  payment_document?: string;
  undue_payment_of_iva?: string;
  undue_payment_available_of_iva?: string;
  undue_payment_used_of_iva?: string;
  undue_payment_available_of_isr?: string;
  source?: DeclarationSource;
  undue_payment_used_of_isr?: string;
  undue_payment_of_isr?: string;
  undue_payment_isr_in_favor?: string;
  undue_payment_iva_in_favor?: string;
  pdf_link?: string;
  declaration_type: DeclarationType;
  missing_information?: boolean;
  declaration_document?: DeclarationDocument;
  declaration_payment?: DeclarationPayment;
  declaration_payers?: DeclarationPayer[];
  total_payroll_withholdings_to_pay: string;
  presented_obligations: string[];
  count_income_entries: number;
  count_expense_entries: number;
  total_late_fees?: string;
  provisional_payment_base?: string;
  accrued_isr_tax_loss?: string;
  presentation_attempts?: PresentationAttempt[];
  declaration_balances?: DeclarationBalance[];
  declaration_transactions?: DeclarationTransaction[];
  presented_at?: DateStr | null;
}

export interface SubmitDeclarationPayload {
  declarationId: number;
  payload?: Record<string, never>;
}
export interface CreateComplementaryDeclarationPayload {
  declarationId: number;
  payload: {
    cascade: boolean;
  };
}

export interface ForeignDocument {
  id: number;
  entry_type: 'foreign_invoices' | 'foreign_products';
  description: string;
  taxable_amount: string;
  amount: string;
  iva: string;
  documents: string | null;
  created_at: DateStr;
  updated_at: DateStr;
  document_id: number;
  file: string | null | undefined;
  foreign_taxpayer_id: number;
  foreign_taxpayer: ForeignTaxpayer;
  taxable_entity_id: number;
  period: DateStr;
  declaration_entries: DeclarationEntry[];
}
export interface ForeignDocumentQuery {
  data: ForeignDocument[];
  meta: {
    total_count: number;
  };
}

/* AUTH */
export interface SingInResponse extends User {
  taxable_entity: TaxableEntity;
}

export interface SingInRequest extends Pick<User, 'email' | 'password'> {
  captcha_token: string;
}

export interface SingInTwoFactorRequest
  extends Pick<User, 'email' | 'otp_token'> {
  otp_code: string;
}

export type GoogleSignInRequest = Omit<
  TokenResponse,
  'error' | 'error_description' | 'error_uri'
>;

/* TAXABLE ENTITY */
export interface TaxableEntityPreference {
  has_diot?: boolean;
  has_fiel?: boolean;
  has_ieps?: boolean;
  must_be_registered_in_tax_mailbox?: boolean;
  positive_op?: string;
  state?: string;
  last_declaration?: DateStr;
  invoice_template?: string;
  invoice_color?: string;
  about_me?: string;
  months_late?: DateStr[];
  past_due_annual_declarations?: number[];
  increment_internal_folio?: boolean;
  internal_folio_prefix?: string;
  internal_folio_number_length?: number;
  unique_internal_folio?: boolean;
  next_internal_folio?: number;
  has_payroll?: boolean;
  preferred_fiscal_regime?: number | null | undefined;
  /* NOTIFICATIONS */
  // #ConsejosKonta
  disable_whatsapp_onb_consejos_konta?: boolean;
  // Información sobre la Línea Konta
  disable_whatsapp_onb_atencion?: boolean;
  disable_email_konta_line?: boolean;
  // Aprender a facturar
  disable_whatsapp_onb_aprender_facturar?: boolean;
  disable_email_invoicing?: boolean;
  // Aprender a facturar PPD
  disable_whatsapp_onb_onb_aprender_facturar_ppd?: boolean;
  disable_email_how_to_invoice_ppd?: boolean;
  // Impuestos 101
  disable_whatsapp_onb_impuestos101?: boolean;
  disable_email_taxes101?: boolean;
  // Mitos sobre los impuestos
  disable_whatsapp_onb_mitos_impuestos?: boolean;
  disable_email_taxes_myths?: boolean;
  // Checkpoint: cómo va tu experiencia?
  disable_whatsapp_onb_checkpoint_1?: boolean;
  disable_email_how_is_your_experience?: boolean;
  // Recordatorio: realiza tu factura al público general
  disable_whatsapp_onb_factura_publico_general?: boolean;
  disable_email_how_to_invoice_general?: boolean;
  auto_submit_tax_declaration?: boolean;
}
export type TaxableEntityPreferenceKey = keyof TaxableEntityPreference;
export type TaxableEntityPreferencePayload = Partial<TaxableEntityPreference>;
export interface TaxableEntityPreferencePatchPayload {
  taxableEntityId: number | string;
  payload: TaxableEntityPreferencePayload;
}
/* DOCUMENTS */
export interface Document {
  file_name: string;
  attachment_url: string;
  category: {
    id: number;
    name: string;
  };
}
export interface ComplianceOpinionDocument {
  file_url: string;
}

export interface ComplianceOpinionDocumentPayload {
  taxable_entity_id: number;
}
export type ComplianceOpinionStatusType =
  | 'Positiva'
  | 'Negativa'
  | 'Sin Obligaciones';
export interface ComplianceOpinionStatus {
  id: number;
  taxable_entity_id: number;
  status: ComplianceOpinionStatusType;
  checked_at: DateStr;
  folio: string;
  source: string;
}

/* TEAMS */
export interface Team {
  id: number;
  name: string;
  slack_group: string;
  extension: string | null;
}

/* REFERRALS */
export interface Referral {
  id: number;
  img: string;
  referral_code: string;
}

/* WORKFLOWS */
export interface Phase {
  id: number;
  key: string;
  name?: string;
  organizer_action?: string;
  created_at: string;
  updated_at: string;
}

export type WorkflowStatus =
  | 'fetching_invoice'
  | 'classifying'
  | 'ready'
  | 'in_proccess'
  | 'verify_declaration'
  | 'submit_declaration'
  | 'pending_payment'
  | 'paid'
  | 'done'
  | 'bank_payment';

export type WorkflowFeedback = {
  id: number;
  evaluated_type: string;
  evaluated_id: number;
  rating: number;
  comment: string;
  created_at: DateStr;
  updated_at: DateStr;
};
export interface Workflow {
  id: number;
  arrears?: boolean;
  workflow_template_id: number;
  taxable_entity_id: number;
  current_phase_key: string;
  start_date: DateStr;
  end_date: DateStr;
  due_date: DateStr;
  updated_at: DateStr;
  created_at: DateStr;
  status: WorkflowStatus;
  payment_status?: string;
  has_feedback: boolean;
  previous_phases: Phase[];
  available_phases: Phase[];
  declarations: Declaration[];
  active_declaration: Declaration;
  active_declarations: Declaration[];
  feedback?: WorkflowFeedback;
  is_initial?: boolean;
}
export interface WorkflowPayload {
  workflow: {
    status?: WorkflowStatus;
    next_phase?: string;
    start_date?: DateStr;
    is_initial?: boolean;
    current_phase_key?: string;
    current_phase_id?: string | number;
    declarations_attributes?: (Partial<Declaration> & {
      id?: string | number;
      total_late_fees?: string | number;
      total_isr_paid?: number | string;
      declaration_income_attributes?: Partial<DeclarationIncome>;
      declaration_expense_attributes?: Partial<DeclarationExpense>;
      declaration_document_attributes?: Partial<DeclarationDocument>;
    })[];
  };
}
export interface WorkflowBulkPayload {
  taxable_entity_id: number;
  is_initial: boolean;
  workflow_templates: Record<number, string[]>;
  arrears: boolean;
}
export interface WorkflowBulkEditPayload {
  workflows: {
    id: number;
    status: WorkflowStatus | null;
  }[];
}

export interface WorkflowUpdatePayload {
  workflowId: number | string;
  payload?: WorkflowPayload;
}
export interface WorkflowNextPhasePayload {
  workflowId: number | string;
  payload?: {
    workflow: {
      next_phase?: string;
    };
  };
}

export interface WorkflowUploadDeclarationAckPayload {
  workflowId: number | string;
  payload: {
    declarations_attributes: {
      id: number | string;
      total_late_fees: number | string;
      declaration_document_attributes: {
        capture_line: string;
        document_attributes: DeclarationDocumentAttributes;
        validation_date: DateStr | null;
        salary_capture_line?: string;
        salary_validation_date?: DateStr | null;
        salary_document_attributes?: DeclarationDocumentAttributes;
      };
    }[];
  };
}

export interface WorkflowDeletePayload {
  workflowId: number | string;
}
export interface WorkflowBulkDeletePayload {
  workflows: (number | string)[];
  taxable_entity_id: number | string;
}
export interface WorkflowApprovePayload {
  workflowId: number | string;
}
export interface RecomputeDeclarationPayload {
  declarationId: number | string;
}
export interface WorkflowSwitchTemplatePayload {
  workflowId: number | string;
  payload: {
    new_template: WorkflowTemplateModelName;
    fiscal_regime_switches: Record<
      WorkflowTemplateModelName,
      WorkflowTemplateModelName
    >;
  };
}
/* -------Address---------*/

export interface Address {
  id: number;
  street: string | null;
  number: string | null;
  street_two: string | null;
  city: string | null;
  state: string | null;
  postcode: string | null;
  country: string | null;
  interior_number: string | null;
  between_street: string | null;
  between_street_two: string | null;
  phone_number: string | null;
  reference: string | null;
  latitude: string | null;
  longitude: string | null;
}
export interface AddressRequestPayload {
  taxableEntityId: number;
  addressId: number;
}

/* -------- INVOICE DRAFT TO FORMIK VALUES --------*/
export interface InvoiceTax {
  amount: number;
  base: number;
  rates: {
    [rate: number]: {
      amount: number;
      base: number;
    };
  };
}
export interface InvoicePayload {
  legalName: string;
  client: ReactSelectItemProps<ClientSupplier> | null;
  concepts: Concept[];
  creditNoteInvoice: ReactSelectItemProps<Cfdi> | null;
  paymentType: ReactSelectItemProps<string> | null;
  periodicity: ReactSelectItemProps<string> | null;
  periodicityMonth?: ReactSelectItemProps<string> | null;
  periodicityYear: string;
  cfdiUsage: ReactSelectItemProps<string> | null;
  taxIdentityRegistrationNumber: string;
  invoiceType: ReactSelectItemProps<string> | null;
  paymentMethod: ReactSelectItemProps<string> | null;
  exportCode: ReactSelectItemProps<string> | null;
  currency: ReactSelectItemProps<string> | null;
  createdDate: string;
  orderNumber: string;
  invoiceNumber: string;
  taxResidency: ReactSelectItemProps<string> | null;
  exchangeRate: string | number;
  postcode: string | null;
  fiscalRegime: ReactSelectItemProps<FiscalRegime> | null;
  total: number;
  subtotal: number;
  discount: number;
  iva: InvoiceTax | null;
  retainedIva: InvoiceTax | null;
  isr: InvoiceTax | null;
  ieps: InvoiceTax | null;
}

export interface Product {
  id: number;
  name: string;
  description: string;
  price: number;
  client_supplier_id: null;
  created_at: DateStr;
  updated_at: DateStr;
  product_id: string;
  taxable_entity_id: number;
  unit_id: number;
  iva: number | null;
  isr: number;
  retained_iva: number;
  ieps: number;
  sat_product_id: string;
  property_tax_account: string;
  deleted_at: null;
  taxable_code: null;
}
export interface ConceptTax {
  rate: number | null;
  amount: number;
}
export interface Concept {
  product: ReactSelectItemProps<Product> | null;
  unit: ReactSelectItemProps<string> | null;
  quantity: string | null;
  price: string;
  amount: number;
  formattedAmount: string;
  subtotal: number;
  formattedSubtotal: string;
  discount: number;
  taxableCode: ReactSelectItemProps<string> | null;
  ieps: ConceptTax | null;
  isr: ConceptTax | null;
  iva: ConceptTax | null;
  retainedIva: ConceptTax | null;
}

/* Accumulated Incomes And Expenses */
export interface AccumulatedIncomeAndExpenseData {
  [key: string]: {
    [key: string]: number;
  };
}
export interface AccumulatedIncomeAndExpense {
  incomes: AccumulatedIncomeAndExpenseData;
  expenses: AccumulatedIncomeAndExpenseData;
}
export interface AccumulatedOfEntries {
  incomes: AccumulatedIncomeAndExpenseData;
  expenses: AccumulatedIncomeAndExpenseData;
}
export type AccumulatedIncomeAndExpenseFilters = 'currentMonth' | 'currentYear';
export type AccumulatedIncomeAndExpenseEntriesFilters = boolean;

/* LINE SERIES CHART */
export type DataPoint = {
  name: string;
  value: number;
  tooltipLabel?: string;
  extraData?: Record<string, number | string>;
};

/* NOTIFICATION CENTER */
export type Notification = {
  name: string;
  whatsappKey?: TaxableEntityPreferenceKey;
  emailKey?: TaxableEntityPreferenceKey;
  underline?: boolean;
  notifications?: Notification[];
};
export type NotificationsType = {
  title: string;
  isGrouped?: boolean;
  notifications: Notification[];
}[];

export interface NotificationWithEmailKey extends Notification {
  emailKey: TaxableEntityPreferenceKey;
}

export interface NotificationWithWhatsappKey extends Notification {
  whatsappKey: TaxableEntityPreferenceKey;
}

/* TOP ACCUMULATED SALES  */
export interface AccumulatedSales {
  amount: GroupDataPoint[];
  count_emitted: GroupDataPoint[];
  count_expense_emitted: GroupDataPoint[];
}
/* KONTA SCORE */
export type KontaScoreStatus =
  | 'Excelente'
  | 'Muy Buena'
  | 'Buena'
  | 'Regular'
  | 'Mala'
  | 'Muy Mala'
  | 'No disponible';
export interface KontaScore {
  score: number;
  last_update: DateStr;
  status: KontaScoreStatus;
}

/* WORKFLOW HEATMAP */
export interface MonthData {
  [key: string]: number;
}
export interface WorkflowHeatmapData {
  [key: string]: MonthData;
}
/* NEW HEATMAP */
export type DeclarationStatusHeatmap =
  | 'no_obligations'
  | 'paid'
  | 'review_required'
  | 'presented'
  | 'not_presented';
export type SubscriptionStatusHeatmap = 'no_subscription' | 'paid' | 'unpaid';
export type DeclarationStatusHeatmapData = {
  declaration_status: DeclarationStatusHeatmap;
  subscription_status: SubscriptionStatusHeatmap;
};
export interface NewWorkflowHeatmapData {
  [key: string]: {
    [key: string]: DeclarationStatusHeatmapData;
  };
}

/* ZENDESK */
export interface ZendeskLoginRequest {
  email: string;
  password: string;
  return_to: string;
}

export type ZendeskLoginResponse = string;

/* CANNY */
export type CannyLoginRequest = string;

/* PUBLIC CLIENT SUPPLIERS  */
export interface PublicClientSupplierCreatePayload {
  taxableEntityUuid: string;
  payload: {
    client_supplier: Partial<ClientSupplier>;
  };
}

/* INVOICE TEMPLATES */
export interface InvoiceTemplate {
  id: number;
  uuid: string;
  taxable_entity_id: number;
  cfdi_id: number;
  status: string;
  body: InvoiceTemplateBody;
  template_name: string;
  email: string;
  phone_number: string;
  formik_values: InvoiceTemplateFormikValues;
  invoice_template: InvoiceDraftTemplate;
  source_template: string;
}
export interface InvoiceDraftTemplate {
  id: number;
  invoice_draft_id: number;
  name: string;
  disabled_at: null;
  created_at: Date;
  updated_at: Date;
}
export interface InvoiceTemplateBody {
  receipt: InvoiceTemplateReceipt;
}

export interface InvoiceTemplateReceipt {
  folio_number: string;
}

export interface InvoiceTemplateFormikValues {}

/* INVOICE XML PARSED */

export interface ParsedInvoiceXML {
  QR: string;
  'xmlns:cfdi': string;
  'xmlns:xsi': string;
  Version: string;
  Folio: string;
  Fecha: Date;
  FormaPago: string;
  SubTotal: string;
  Moneda: string;
  Total: string;
  TipoDeComprobante: string;
  MetodoPago: string;
  LugarExpedicion: string;
  Exportacion: string;
  'xsi:schemaLocation': string;
  NoCertificado: string;
  Certificado: string;
  Sello: string;
  Conceptos: Concepto[];
  Impuestos: Impuestos;
  Complemento: Complemento;
  Retenciones: Retenciones;
  Traslados: TrasladoElement[];
  Emisor: Emisor;
  Receptor: Receptor;
  PaymentDetail: {
    FechaPago: string;
    FormaDePagoP: string;
    MonedaP: string;
    TipoCambioP: string;
    Monto: string;
  };
}

export interface ParsedConstancyXML {
  constancyEmisor: string;
  constancyRfcEmisor: string;
  constancyReceptor: string;
  constancyMontoTotOperacion: string;
  constancyFechaTimbrado: string;
}

export interface Complemento {
  'xmlns:tfd': string;
  'xmlns:xsi': string;
  SelloSAT: string;
  NoCertificadoSAT: string;
  SelloCFD: string;
  FechaTimbrado: Date;
  UUID: string;
  Version: string;
  RfcProvCertif: string;
  'xsi:schemaLocation': string;
}

export interface Concepto {
  $: ConceptoClass;
  'cfdi:Impuestos': CfdiImpuesto[];
}

export interface ConceptoClass {
  ClaveProdServ: string;
  Cantidad: string;
  ClaveUnidad: string;
  Unidad: string;
  Descripcion: string;
  ValorUnitario: string;
  Importe: string;
  ObjetoImp: string;
}

export interface CfdiImpuesto {
  'cfdi:Traslados': CfdiTraslado[];
}

export interface CfdiTraslado {
  'cfdi:Traslado': TrasladoElement[];
}

export interface TrasladoElement {
  $: Traslado;
}

export interface Traslado {
  Base: string;
  Impuesto: string;
  TipoFactor: string;
  TasaOCuota: string;
  Importe: string;
}

export interface Emisor {
  Rfc: string;
  Nombre: string;
  RegimenFiscal: string;
}

export interface Impuestos {
  TotalImpuestosTrasladados: string;
}

export interface Receptor {
  Rfc: string;
  Nombre: string;
  UsoCFDI: string;
  DomicilioFiscalReceptor: string;
  RegimenFiscalReceptor: string;
}

export interface Retenciones {}

export interface DeclarationAttributes {
  declaration: {
    declaration_income_attributes: {
      extra_iva_bases: number | string;
      extra_zero_bases: number | string;
      extra_iva_rate: number | string;
    };
  };
}

export interface DeclarationPaymentPayload {
  declarationId: number | string;
  payload?: FormData;
}
export interface DeclarationUpdatePayload {
  declarationId: number | string;
  payload?:
    | {
        declaration: {
          total_late_fees?: number | string;
          isr_tax_loss?: string | number;
          operation_number?: number | string;
          balanceable_id?: number;
          operated_at?: DateStr;
          balanceable_type?: string;
          undue_payment_of_iva?: number;
          undue_payment_available_of_iva?: number;
          undue_payment_of_isr?: number;
          undue_payment_available_of_isr?: number;
          total_payroll_withholdings_to_pay?: number;
          available_amount?: number;
          used_amount?: number;
          presented_at?: DateStr;
          operation_type?: string;
          balance_type?: string;
          declaration_income_attributes?: {
            taxes_withheld_of_payroll?: number;
            employment_subsidy_of_payroll?: number;
            extra_iva_bases?: number | string;
            extra_zero_bases?: number | string;
            extra_iva_rate?: number | string;
            source_of_income?: SourceOfIncome;
          };
          declaration_document_attributes?: {
            capture_line: string;
            document_attributes: DeclarationDocumentAttributes;
            validation_date: DateStr | null;
            salary_capture_line?: string;
            salary_validation_date?: DateStr | null;
            salary_document_attributes?: DeclarationDocumentAttributes;
          };
        };
      }
    | FormData;
}

/* FOREIGN TAX PAYER */
export interface ForeignTaxPayer {
  id: number;
  social_reason: string;
  country: string;
  registration_date: string;
  status: string;
}

/* DECLARATION ENTRIES ANALITICS */

export interface DeclarationEntriesAnalytics {
  total_count: number;
  in_review_count: number;
  categorized_count: number;
  not_categorized_count: number;
  categorized_by_ai_count: number;
  categorized_by_accountant_count: number;
  categorized_by_user_count: number;
}

/* PERSONAL DEDUCTIONS */

export interface PersonalDeduction {
  category: string;
  total: number;
  id: number | null;
}

/* DELCARATION BALANCES */

export interface DeclarationTransaction {
  id: number;
  declaration_consumer_id: number;
  declaration_payer_id: number | null;
  iva_amount: string;
  declaration_balance_type: DeclarationTransactionBalanceType;
  created_at: string;
  updated_at: string;
  isr_amount: string;
  amount: string;
  transaction_type: DeclarationTransactionType;
  payable_type: string;
  payable_id: number;
  declaration_consumer: Declaration;
  payable: {
    type: string;
    declaration_balance: DeclarationBalance;
  };
}

export interface BalanceableQuestionAndAnswer {
  loans_amount: number;
  donations_amount: number;
  vehicle_sale_date: string;
  vehicle_sale_amount: number;
  real_state_sale_amount: number;
  governmental_support_amount: number;
}

export interface AnnualDeclarationBalanceable {
  id: number;
  taxable_entity_id: number;
  total: string;
  status: string;
  period: number;
  annual_rate: string;
  profit_or_loss: string;
  questions_and_answers: BalanceableQuestionAndAnswer;
  total_calculated: string;
  profit_or_loss_calculated: string;
  tax_loss: string;
  profit_or_loss_resico: string;
  operation_number: string | null;
  operation_type: string;
  paid_amount: string;
  payment_date: string | null;
  source: string | null;
  sat_ws_id: string | null;
  fiscal_regime_id: number | null;
}
export type DeclarationBalanceType =
  | 'iva'
  | 'isr'
  | 'undue_payment_iva'
  | 'undue_payment_isr'
  | 'isr_tax_loss';
export interface CommonDeclarationBalance {
  id: number;
  balanceable_id: number;
  available_amount: number;
  used_amount: number;
  operation_number: string | number;
  presented_at: string;
  operation_type: string;
  balance_type: DeclarationBalanceType;
  declaration_regime: null;
  created_at: string;
  declaration_transactions: DeclarationTransaction[];
  initial: boolean;
}
export enum DeclarationBalanceableType {
  Declaration = 'Declaration',
  AnnualDeclaration = 'AnnualDeclaration',
}
export type DeclarationBalanceable = {
  type: 'declaration';
  declaration: Declaration;
};
export type AnualBalanceable = {
  type: 'annual_declaration';
  annual_declaration: AnnualDeclarationBalanceable;
};

export type DeclarationBalance = CommonDeclarationBalance &
  (
    | {
        balanceable: DeclarationBalanceable;
        balanceable_type: DeclarationBalanceableType.Declaration;
      }
    | {
        balanceable: AnualBalanceable;
        balanceable_type: DeclarationBalanceableType.AnnualDeclaration;
      }
  );

export interface DeclarationBalancePayload {
  balanceable_id: number;
  available_amount: number;
  used_amount: number;
  operation_number: string | number;
  presented_at: string;
  operation_type: string;
  balance_type: string;
  balanceable_type: 'Declaration' | 'AnnualDeclaration';
}
export interface DeclarationBalancePatchPayload {
  balanceable_id?: number;
  available_amount?: number;
  used_amount?: number;
  operation_number?: string | number;
  presented_at?: string;
  operation_type?: string;
  balance_type?: string;
  balanceable_type?: 'Declaration' | 'AnnualDeclaration';
}

/*  REGIME WITH DECLARATION */
export interface RegimeWithDeclaration {
  label: string;
  value: string;
  declarationId: number;
  satKey: number;
  startDate: string;
  taxableEntityId: number;
}

/* DEDUCITON LIMITS */

export interface GovernmentDeductionLimit {
  amount: number;
  category: string;
  id: number;
  period: number;
}
export interface AnnualDeductionSubcategory {
  id: number;
  name: string;
  amount: number;
  limit: number;
  government_deduction_limit: GovernmentDeductionLimit;
}

export interface AnnualDeductionCategory {
  name: string;
  amount: number;
  limit: number;
  subcategories: AnnualDeductionSubcategory[];
}

export interface SelectedCategory extends AnnualDeductionCategory {
  category: AnnualDeductionCategoryKey | null;
}

export interface MonthlyIncomeEstimate {
  id: number;
  annual_deduction_id: number;
  status: 'open' | 'closed';
  amount: number;
  year_period: number;
  month_period: number;
}

export type AnnualDeductionCategoryKey =
  | 'health'
  | 'education'
  | 'mortgage_interest'
  | 'retirement_fund'
  | 'donations'
  | 'insurance'
  | 'funeral_expenses'
  | 'school_transportation';

export interface AnnualDeduction {
  id: number;
  taxable_entity_id: number;
  period: number;
  income_accumulated: number;
  income_deduction_percentage: number;
  umas: number;
  deduction_accumulated: number;
  limit: number;
  umas_amount: number;
  income_deduction: number;
  annual_deduction_categories: Record<
    AnnualDeductionCategoryKey,
    AnnualDeductionCategory | undefined
  >;
  monthly_income_estimates: MonthlyIncomeEstimate[];
}

export interface AnnualDeductionPayload {
  monthly_income_estimates_attributes: {
    id: number;
    amount: number;
  }[];
}

/* FISCAL OBLIGATIONS */

export interface FiscalObligation {
  id: number;
  taxable_entity_id: number;
  obligation_id: number;
  created_at: DateStr;
  updated_at: DateStr;
  start_date: DateStr;
  end_date: DateStr;
  due_date: string;
  last_updated: DateStr | null;
}

export interface FiscalObligationPayload {
  taxable_entity_id: number;
  obligation_id: number | null;
  start_date: DateStr;
  end_date: DateStr;
  due_date: DateStr;
}

/* FOREIGN INVOICE */

export interface EditForeignInvoicePayload {
  amount: number | null;
  iva: number | null;
  foreign_taxpayer_id: number | null;
  period: DateStr | null;
}

export interface UpdateForeignInvoiceRequest {
  foreignInvoiceId: number;
  payload: EditForeignInvoicePayload;
}
