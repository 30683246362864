import { styled } from '@konta/ui';

const Main = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0',
  background: '$white',
  height: '100%',
  variants: {
    blockUi: {
      true: {
        pointerEvents: 'none',
        userSelect: 'none',
        opacity: 1,
        filter: 'blur(3px)',
      },
    },
  },
});

export default Main;
