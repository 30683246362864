import React, { useMemo } from 'react';
import { Flex, Text, Divider } from '@konta/ui';
import toFixedString from '@util/toFixedString';
// eslint-disable-next-line import/no-cycle
import toCurrency from '@util/toCurrency';
import subscriptionService from 'shared/services/subscription';

function PlanDescriptionText({ bold, children, css = {} }) {
  return (
    <Text
      color={bold ? 'gray900' : 'gray600'}
      css={css}
      s
      lineHeight="s"
      medium
    >
      {children}
    </Text>
  );
}

function PlanDivider() {
  return (
    <Flex
      css={{ padding: '10px 0 0', hr: { marginBottom: '0' } }}
      direction="column"
    >
      <Divider />
    </Flex>
  );
}

export default function UserPlanCheckout({ plan, lastCoupon }) {
  const invoice = useMemo(() => {
    const ivaPrice = 0.16;
    const totalExtra = +plan.subscription.extra;
    const durationMonths = +plan.subscription.durationMoths;
    const discount = lastCoupon?.coupon ?? plan.discount.value;
    const basePrice = +plan.subscription.basePrice;
    const planServiceDb = plan.subscription.service_db;

    const planPrice = () => {
      if (durationMonths === 6 || durationMonths === 12) {
        return +basePrice * durationMonths;
      }

      return +plan.subscription.price * durationMonths;
    };

    const subtotal = toFixedString(planPrice() + totalExtra);
    const totalIva = toFixedString(+subtotal * ivaPrice);
    const totalPlanPrice = toFixedString(+subtotal * 1.16);
    const { discount: totalPlanDiscount, discountText } =
      subscriptionService.utils.calculatePlanDiscountAndTotal(
        discount,
        +totalPlanPrice,
        durationMonths,
        planServiceDb,
      );
    const total = toFixedString(+totalPlanPrice - +totalPlanDiscount);

    return {
      subtotal,
      totalIva,
      totalPlanPrice,
      totalPlanDiscount: toFixedString(totalPlanDiscount),
      planPrice: toFixedString(planPrice()),
      total,
      durationMonths,
      basePrice,
      discountText,
    };
  }, [
    plan.discount.value,
    plan.subscription.durationMoths,
    plan.subscription.extra,
    plan.subscription.price,
    lastCoupon,
    plan.subscription.service_db,
    plan.subscription.basePrice,
  ]);
  return (
    <Flex
      css={{
        padding: '16px',
        my: '$10',
        border: '1px solid #EAECF0',
        borderRadius: '8px',
        width: '100%',
        '@md': {
          width: '75%',
        },
        '@lg': {
          width: '50%',
        },
      }}
      direction="column"
    >
      {/* Product */}
      <Flex css={{ padding: '8px 0 0' }} justify="between">
        {/* Plan a qty */}
        <Flex direction="column">
          <PlanDescriptionText bold>
            {plan.subscription.service}
          </PlanDescriptionText>
          <Text xs lineHeight="xs" color="gray500">
            Cantidad {invoice.durationMonths}
          </Text>
        </Flex>
        {/* Price and unit */}
        <Flex direction="column" align="end">
          <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
            {toCurrency(+invoice.planPrice)} MXN
          </PlanDescriptionText>
          <Text xs lineHeight="xs" color="gray500" css={{ textAlign: 'end' }}>
            {toCurrency(+invoice.planPrice / +invoice.durationMonths)} MXN por
            unidad
          </Text>
        </Flex>
      </Flex>
      {/* Subtotal */}
      <Flex css={{ padding: '24px 0 0' }} justify="between">
        <PlanDescriptionText bold>Subtotal</PlanDescriptionText>
        <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+invoice.subtotal)} MXN
        </PlanDescriptionText>
      </Flex>
      {/* Divider */}
      <PlanDivider />
      {/* Total before taxes */}
      <Flex css={{ padding: '14px 0 0' }} justify="between">
        <PlanDescriptionText bold css={{ flex: 1 }}>
          Total antes de impuestos
        </PlanDescriptionText>
        <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+invoice.subtotal)} MXN
        </PlanDescriptionText>
      </Flex>
      {/* Total taxes */}
      <Flex css={{ padding: '14px 0 0' }} justify="between">
        <PlanDescriptionText>Impuestos (16%)</PlanDescriptionText>
        <PlanDescriptionText css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+invoice.totalIva)} MXN
        </PlanDescriptionText>
      </Flex>
      {/* Discount */}
      {invoice.totalPlanDiscount !== '0.00' && (
        <Flex css={{ padding: '14px 0 0' }} justify="between">
          <PlanDescriptionText css={{ flex: 1 }}>
            Descuento {invoice.discountText}
          </PlanDescriptionText>
          <PlanDescriptionText css={{ flex: 1, textAlign: 'end' }}>
            -{toCurrency(+invoice.totalPlanDiscount)} MXN
          </PlanDescriptionText>
        </Flex>
      )}
      {/* Divider */}
      <PlanDivider />
      {/* Total */}
      <Flex css={{ padding: '14px 0 0' }} justify="between">
        <PlanDescriptionText bold>Total</PlanDescriptionText>
        <PlanDescriptionText bold css={{ flex: 1, textAlign: 'end' }}>
          {toCurrency(+invoice.total)} MXN
        </PlanDescriptionText>
      </Flex>
    </Flex>
  );
}
