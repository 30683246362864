import { ComponentType } from 'react';
import { WorkflowStatus as IWorkflowStatus } from './entities';

export enum WorkflowPhaseKeyType {
  Intro = 'intro',
  DeclarationInfo = 'declaration_info',
  IncomesByRegime = 'incomes_by_regime',
  ExpensesByRegime = 'expenses_by_regime',
  ResumeDeclaration = 'resume_declaration',
  PendingDeclaration = 'pending_declaration',
  CaptureLinePayment = 'capture_line_payment',
  TaxesPayment = 'taxes_payment',
}
export enum WorkflowPhaseKeyRouteType {
  Intro = '/app/workflow/:workflowId/intro',
  DeclarationInfo = '/app/workflow/:workflowId/declaration-info',
  IncomesByRegime = '/app/workflow/:workflowId/incomes-by-regime',
  ExpensesByRegime = '/app/workflow/:workflowId/expenses-by-regime',
  ResumeDeclaration = '/app/workflow/:workflowId/resume',
  PendingDeclaration = '/app/declaraciones?month=:month&year=:year',
  CaptureLinePayment = '/app/workflow/:workflowId/capture-line-payment',
  TaxesPayment = '/app/workflow/:workflowId/taxes-payment',
}
export type WorkflowPhases = {
  [type in WorkflowPhaseKeyType]: WorkflowPhaseKeyRouteType;
};

export type StatusContent = {
  IconComponent: ComponentType;
  title: string;
  description: string;
  btnText?: string;
};
export type StatusMap = {
  [key in IWorkflowStatus]: StatusContent;
};

export enum WorkflowPhaseKey {
  Intro = 'intro',
  DeclarationInfo = 'declaration_info',
  IncomesByRegime = 'incomes_by_regime',
  ExpensesByRegime = 'expenses_by_regime',
  ResumeDeclaration = 'resume_declaration',
  PendingDeclaration = 'pending_declaration',
  CaptureLinePayment = 'capture_line_payment',
  TaxesPayment = 'taxes_payment',
  Paperwork = 'paperwork',
  ConstancySummary = 'constancy_summary',
  QuNotPlatformIncome = 'qu_not_platform_income',
  CreateForignAndPublicInvoice = 'create_forign_and_public_invoice',
  NotPlatformIncome = 'not_platform_income',
  MisingPublicInvoice = 'mising_public_invoice',
  TotalConstancyExceeded = 'total_constancy_exceeded',
  HasCsd = 'has_csd',
  NotCsdUploadInvoice = 'not_csd_upload_invoice',
  TextExpences = 'text_expences',
  QuForeignExpences = 'qu_foreign_expences',
  AddForeignExpence = 'add_foreign_expence',
  ExpencesClassification = 'expences_classification',
  WorkflowEnd = 'workflow_end',
  IntroResico = 'intro_resico',
  SelectIncomeUploadWay = 'select_income_upload_way',
  UploadIncomeTotal = 'upload_income_total',
  BelvoConnection = 'belvo_connection',
  UploadTransaction = 'upload_transaction',
  HasExtraIncome = 'has_extra_income',
  ValidateIncomeDifference = 'validate_income_difference',
  HasExemptIva = 'has_exempt_iva',
  ClassifyTransaction = 'classify_transaction',
  ValidateIncomeReviewRequired = 'validate_income_review_required',
  IncomeReviewRequired = 'income_review_required',
  ValidateTransactionUpload = 'validate_transaction_upload',
  CreateMissingPublicInvoiceResico = 'create_missing_public_invoice_resico',
  NotCsdUploadInvoiceResico = 'not_csd_upload_invoice_resico',
  TextExpensesResico = 'text_expenses_resico',
  QuForeignExpencesResico = 'qu_foreign_expences_resico',
  AddForeignExpenceResico = 'add_foreign_expence_resico',
  ExpencesClassificationResico = 'expences_classification_resico',
  HasCsdResico = 'has_csd_resico',
  CaptureLinePaymentResico = 'capture_line_payment_resico',
  ClassifyIncomesResico = 'classify_incomes_resico',
  HasRetentionsResico = 'has_retentions_resico',
  ValidateIncomeAdjustment = 'validate_income_adjustment',
  InvoicesIncomeVerification = 'invoices_income_verification',
  ComputeResultResico = 'compute_result_resico',
  ComputeResult = 'compute_result',
}
