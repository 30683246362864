import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Flex } from '@konta/ui';
import useSubscriptions from '@hooks/useSubscriptions';
import Pricing from '@components/Pricing';
import { PricingPlans } from '@components/Price';
import UserPlanCheckout from '@components/UserSubscription/UserPlanCheckout';
import { useTaxableEntity } from '@hooks';
import useGetTaxableEntityCouponsQuery from '@hooks/useGetTaxableEntityCouponsQuery';
import useFeature from '@hooks/useFeature';
import { NEW_PRICING_PLANS } from '@constants/featureFlags';
import { Discount, PaymentTabs } from '../Payment';
import { SubmitButton } from '../Buttons';
import NextPayment from '../Payment/NextPayment';

export default function UserPlans({ asCards }) {
  const { handleSubscriptionPayment, hidePrices, ui, loading } =
    useSubscriptions();
  const [isNewPricingEnabled] = useFeature(NEW_PRICING_PLANS);
  const { taxable_entity } = useTaxableEntity();
  const { lastCoupon } = useGetTaxableEntityCouponsQuery(taxable_entity.id);

  if (ui.display_prices) {
    return isNewPricingEnabled ? (
      <Pricing asCard={asCards} />
    ) : (
      <PricingPlans card={asCards} />
    );
  }
  if (ui.selected_subscription?.subscription) {
    const { subscription } = ui.selected_subscription;
    return (
      <Card className="mb-4">
        <CardBody>
          <PaymentTabs
            tabs={['task.payment-methods-card']}
            handleReturn={hidePrices}
            cardTitle="Mi Plan"
            filter
          >
            <Flex justify="center">
              <UserPlanCheckout
                plan={ui.selected_subscription}
                lastCoupon={lastCoupon}
              />
            </Flex>
            <NextPayment plan={ui.selected_subscription} />
            {subscription.billingPeriod === 'monthly' && <Discount />}
            <SubmitButton
              className="mt-4 d-flex align-items-center justify-content-center mb-5 mt-3"
              text="Pagar ahora"
              loading={loading}
              handler={handleSubscriptionPayment}
            />
          </PaymentTabs>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card className="p-4">
      Ocurrió un error al cargar los planes, actualiza la página.
    </Card>
  );
}
