import { useMemo } from 'react';
import useHasPayrollRetention from '@hooks/useHasPayrollRetention';
import { IS_LOCAL, IS_PRODUCTION } from '@constants/enviroments';
import calculateDeclarationsSummary from './utils/calculateDeclarationsSummary';
import generatePayrollTaxesRow from './utils/generatePayrollTaxesRow';
import useWorkflow from '../hooks/useWorkflow';
import generateRegimeTotalRows from './utils/generateRegimeTotalRows';
import generateRegimeBalanceRows from './utils/generateRegimeBalanceRows';

export default function useResumeDeclaration() {
  const {
    activeDeclarations,
    currentRegimes,
    workflowId,
    isLoadingWorkflow,
    workflow,
  } = useWorkflow();
  const { loadingPayrollRetention, hasPayrollRetentions } =
    useHasPayrollRetention();

  const declarationSummary = useMemo(
    () => calculateDeclarationsSummary(activeDeclarations),
    [activeDeclarations],
  );

  const payrollTaxesRow = useMemo(
    () => generatePayrollTaxesRow(declarationSummary).flat(),
    [declarationSummary],
  );
  const balanceTaxesRow = useMemo(
    () => generateRegimeBalanceRows(declarationSummary),
    [declarationSummary],
  );

  const rowsByDeclaration = useMemo(
    () => generateRegimeTotalRows(declarationSummary),
    [declarationSummary],
  );

  const isLoading = isLoadingWorkflow || loadingPayrollRetention;

  const isNextBtnEnabled = IS_PRODUCTION || IS_LOCAL;

  return {
    isLoading,
    activeDeclarations,
    currentRegimes,
    workflowId,
    totalNextPeriodIva: declarationSummary.totalNextPeriodIva,
    hasPayrollRetentions,
    payrollTaxesRow,
    workflow,
    rowsByDeclaration,
    totalNextPeriodIsr: declarationSummary.totalNextPeriodIsr,
    balanceTaxesRow,
    isNextBtnEnabled,
  };
}
