import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import BalancesRoutes from './Customer/CustomerTabs/Taxes/Balances/BalancesRoutes';

const Customers = lazy(() => import('./Customers'));
const Customer = lazy(() => import('./Customer'));
const TaxArrears = lazy(() => import('./Customer/CustomerTabs/TaxArrears'));
const TaxArrear = lazy(
  () => import('./Customer/CustomerTabs/TaxArrears/TaxArrearModal'),
);
const AccountInformation = lazy(
  () => import('./Customer/CustomerTabs/AccountInformation'),
);
const Settings = lazy(() => import('./Customer/CustomerTabs/Settings'));
const Subscription = lazy(() => import('./Customer/CustomerTabs/Subscription'));
const FiscalSituation = lazy(
  () => import('./Customer/CustomerTabs/FiscalSituation'),
);
const Onboarding = lazy(() => import('./Customer/CustomerTabs/Onboarding'));
const Workflow = lazy(
  () => import('./Customer/CustomerTabs/FiscalSituation/Workflow'),
);
const Taxes = lazy(() => import('./Customer/CustomerTabs/Taxes'));
const Entries = lazy(() => import('./Customer/CustomerTabs/Taxes/Entries'));
const Declarations = lazy(
  () => import('./Customer/CustomerTabs/Taxes/Declarations'),
);
const Cfdis = lazy(() => import('./Customer/CustomerTabs/Taxes/Cfdis'));
const Assets = lazy(() => import('./Customer/CustomerTabs/Taxes/Assets'));
const Constancies = lazy(
  () => import('./Customer/CustomerTabs/Taxes/Constancies'),
);
const ForeignInvoices = lazy(
  () => import('./Customer/CustomerTabs/Taxes/ForeignInvoices'),
);
const ForeignProduct = lazy(
  () => import('./Customer/CustomerTabs/Taxes/ForeignProduct'),
);
const TaxesGrid = lazy(() => import('./Customer/CustomerTabs/Taxes/TaxesGrid'));
const SingleSimplifiedCalculation = lazy(
  () =>
    import(
      './Customer/CustomerTabs/Taxes/TaxesGrid/SingleSimplifiedCalculation'
    ),
);
const MultipleSimplifiedCalculation = lazy(
  () =>
    import(
      './Customer/CustomerTabs/Taxes/TaxesGrid/MultipleSimplifiedCalculation'
    ),
);
const SummaryByFiscalRegimeKey = lazy(
  () =>
    import('./Customer/CustomerTabs/Taxes/TaxesGrid/SummaryByFiscalRegimeKey'),
);
const PaymentDetail = lazy(
  () => import('./Customer/CustomerTabs/Taxes/PaymentDetail'),
);
const FiscalSettings = lazy(
  () => import('./Customer/CustomerTabs/FiscalSettings'),
);
const FiscalObligations = lazy(
  () => import('./Customer/CustomerTabs/FiscalSettings/FiscalObligations'),
);

const CustomerConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'customers',
      element: <Customers />,
    },
    {
      path: 'customers/:customerId',
      element: <Customer />,
      children: [
        {
          path: '',
          element: <Navigate replace to="account-information" />,
        },
        {
          path: 'tax-arrears',
          element: <TaxArrears />,
          children: [
            {
              path: ':taxArrearId/*',
              element: <TaxArrear />,
            },
          ],
        },
        {
          path: 'taxes',
          element: <Taxes />,
          children: [
            ...BalancesRoutes,
            {
              path: '',
              element: <Navigate replace to="entries" />,
            },
            {
              path: 'taxes-grids',
              element: <TaxesGrid />,
              children: [
                {
                  path: 'single-simplified-calculation',
                  element: <SingleSimplifiedCalculation />,
                },
                {
                  path: 'multiple-simplified-calculation',
                  element: <MultipleSimplifiedCalculation />,
                },
                {
                  path: 'summary-by-fiscal-regime/:fiscalRegimeKey',
                  element: <SummaryByFiscalRegimeKey />,
                },
              ],
            },
            {
              path: 'entries',
              element: <Entries />,
            },
            {
              path: 'declarations',
              element: <Declarations />,
            },
            {
              path: 'cfdis',
              element: <Cfdis />,
            },
            {
              path: 'assets',
              element: <Assets />,
            },
            {
              path: 'constancies',
              element: <Constancies />,
            },
            {
              path: 'foreign-invoices',
              element: <ForeignInvoices />,
            },
            {
              path: 'pediments',
              element: <ForeignProduct />,
            },
            {
              path: 'payment-detail',
              element: <PaymentDetail />,
            },
          ],
        },
        {
          path: 'account-information',
          element: <AccountInformation />,
        },
        {
          path: 'fiscal-situation',
          element: <FiscalSituation />,
        },
        {
          path: 'fiscal-situation/workflow/:startDate',
          element: <Workflow />,
        },
        {
          path: 'settings',
          element: <Settings />,
        },
        {
          path: 'subscription',
          element: <Subscription />,
        },
        {
          path: 'onboardin-procedures',
          element: <Onboarding />,
        },
        {
          path: 'fiscal-settings',
          element: <FiscalSettings />,
          children: [
            {
              path: '',
              element: <Navigate replace to="obligations" />,
            },
            {
              path: 'obligations',
              element: <FiscalObligations />,
            },
          ],
        },
      ],
    },
  ],
};

export default CustomerConfig;
