import { FORMAT_MONTH } from 'shared/constants/grids';
import { generateMonthNames, getMonthNameFromDate } from 'shared/util/grids';
import type { Declaration, Workflow, WorkflowStatus } from 'types/entities';
import type { SimplifiedDataGrid } from 'types/grids';

const ALLOWED_WORKFLOW_STATUS = [
  'submit_declaration',
  'pending_payment',
  'paid',
  'done',
];

const getDataFromDeclarations = (
  declarations: Declaration[],
  satKey: number[],
  workflowStatus?: WorkflowStatus,
) =>
  declarations
    .filter(
      (declaration) =>
        declaration.is_active &&
        satKey.includes(declaration?.fiscal_regime?.sat_key),
    )
    .reduce(
      (data, declaration) => {
        const { declaration_income, declaration_expense } = declaration;
        const totalIncomes = +declaration_income.total;
        const totalExpenses = +declaration_expense.total;
        const ivaInFavorOrAgainst = +declaration.iva_total;
        const isrToPay = +declaration.isr_to_pay;
        const salaryRetentions =
          +declaration.declaration_income.taxes_withheld_of_payroll;
        const totalLateFees = +(declaration.total_late_fees || 0);
        const ivaToPay = +declaration.iva_to_pay;
        const ivaInFavor = +declaration.iva_in_favor;
        const isrTotal = +declaration.isr_total;
        const isrInFavor = +declaration.isr_in_favor;
        const totalRetainedIvaOfCfdis =
          +declaration.declaration_income.total_retained_iva_of_cfdis;

        const taxToCharge =
          ALLOWED_WORKFLOW_STATUS.includes(workflowStatus || '') && ivaToPay > 0
            ? +ivaToPay
            : +ivaToPay - Math.min(+ivaToPay, ivaInFavor);

        const isrInFavorOrAgainst =
          ALLOWED_WORKFLOW_STATUS.includes(workflowStatus || '') && isrTotal > 0
            ? +isrTotal
            : +isrTotal - Math.min(+isrTotal, isrInFavor);

        const totalToPayOfPeriod =
          Math.max(taxToCharge, 0) +
          Math.max(isrInFavorOrAgainst, 0) +
          +totalRetainedIvaOfCfdis +
          +(totalLateFees || 0);

        return {
          totalIncome: data.totalIncome + totalIncomes,
          totalExpense: data.totalExpense + totalExpenses,
          profitOrLoss: data.profitOrLoss + (totalIncomes - totalExpenses),
          ivaInFavorOrAgainst: data.ivaInFavorOrAgainst + ivaInFavorOrAgainst,
          isrToPay: data.isrToPay + isrToPay,
          salaryRetentions: data.salaryRetentions + salaryRetentions,
          totalLateFees: data.totalLateFees + totalLateFees,
          totalToPayOfPeriod: data.totalToPayOfPeriod + totalToPayOfPeriod,
          taxToCharge: data.taxToCharge + taxToCharge,
          isrInFavorOrAgainst: data.isrInFavorOrAgainst + isrInFavorOrAgainst,
        };
      },
      {
        totalIncome: 0,
        totalExpense: 0,
        profitOrLoss: 0,
        ivaInFavorOrAgainst: 0,
        isrToPay: 0,
        salaryRetentions: 0,
        totalLateFees: 0,
        taxToCharge: 0,
        isrInFavorOrAgainst: 0,
        totalToPayOfPeriod: 0,
      },
    );

interface GetSimplifiedDataGridOpts {
  satKey: number[];
  workflows: Workflow[];
}

export default function getSimplifiedDataGridBySatKeys({
  satKey,
  workflows,
}: GetSimplifiedDataGridOpts) {
  return generateMonthNames(FORMAT_MONTH).reduce<SimplifiedDataGrid>(
    (acc, month) => {
      const workflow = workflows.find(
        (wf) =>
          getMonthNameFromDate({
            date: wf.start_date,
            format: FORMAT_MONTH,
          }) === month,
      );

      const amounts = getDataFromDeclarations(
        workflow?.declarations || [],
        satKey,
        workflow?.status,
      );

      acc.totalIncomes.push(amounts.totalIncome);
      acc.totalExpenses.push(amounts.totalExpense);
      acc.profitOrLoss.push(amounts.profitOrLoss);
      acc.ivaInFavorOrAgainst.push(amounts.ivaInFavorOrAgainst);
      acc.isrToPay.push(amounts.isrToPay);
      acc.salaryRetentions.push(amounts.salaryRetentions);
      acc.totalLateFees.push(amounts.totalLateFees);

      return acc;
    },
    {
      totalIncomes: [],
      totalExpenses: [],
      profitOrLoss: [],
      isrToPay: [],
      ivaInFavorOrAgainst: [],
      salaryRetentions: [],
      totalLateFees: [],
      totalToPay: [],
      ivaInFavorFromPeriods: [],
    },
  );
}
