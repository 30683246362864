import toFixedString from 'shared/util/toFixedString';
import {
  DURATION_TO_BILLING_PERIOD,
  DEFAULT_DISCOUNT,
  ADDON_PRICES,
  DISCOUNTS,
  BILLING_PERIOD_TEXT,
} from 'shared/constants/subscriptions';
import type {
  BadgeText,
  BasePrices,
  BillingPeriod,
  CouponType,
  DiscountAndText,
  Discounts,
  InformationTexts,
  Plan,
  PlanInformationTexts,
  PlanPrices,
  PlanPricing,
} from 'types/subscription';

export function calculatePriceWithDiscount(price: number, discount: number) {
  return Math.round(price * (1 - discount));
}
export function calculateDiscountedAmount(price: number, discount: number) {
  return price * discount;
}
export function calculateDiscount(discount: number) {
  return parseFloat((discount * 100).toFixed(1));
}

export const calculateCouponDiscount = (
  total: number,
  coupon: CouponType,
): DiscountAndText => {
  if (coupon && coupon.discount_method && coupon.amount && coupon.active) {
    const { discount_method, amount } = coupon;

    if (discount_method === 'factor') {
      const discount = total * +amount;
      const percent = toFixedString(+amount * 100);
      const discountText = `de referido (${percent}% de descuento)`;
      return { discount, discountText };
    }
    if (discount_method === 'subtraction') {
      const discount = +amount;
      const discountText = `de referido`;
      return { discount, discountText };
    }
  }
  return DEFAULT_DISCOUNT;
};
export const calculateBillingPeriodDiscount = (
  total: number,
  planServiceDb: Plan,
  durationMonths: number,
): DiscountAndText => {
  if (durationMonths === 6 || durationMonths === 12) {
    const billingPeriod = DURATION_TO_BILLING_PERIOD[durationMonths];
    const amountPercent = DISCOUNTS[planServiceDb][billingPeriod];
    const discount = calculateDiscountedAmount(total, amountPercent);
    const billingPeriodText = BILLING_PERIOD_TEXT[billingPeriod];
    const percent = calculateDiscount(amountPercent);
    const discountText = `por pago ${billingPeriodText} (${percent}% de descuento)`;
    return { discount, discountText };
  }
  return DEFAULT_DISCOUNT;
};
export const calculatePlanDiscount = (
  total: number,
  coupon: CouponType,
  planServiceDb: Plan,
  durationMonths: number,
) => {
  const { discount: planDiscount, discountText: planDiscountText } =
    calculateBillingPeriodDiscount(total, planServiceDb, durationMonths);
  const { discount: couponDiscount, discountText: couponDiscountText } =
    calculateCouponDiscount(total, coupon);

  if (planDiscount > 0) {
    return { discount: planDiscount, discountText: planDiscountText };
  }
  if (couponDiscount > 0) {
    return { discount: couponDiscount, discountText: couponDiscountText };
  }

  return DEFAULT_DISCOUNT;
};

export const calculatePlanDiscountAndTotal = (
  coupon: CouponType,
  total: number,
  durationMonths: number,
  planServiceDb: Plan,
) => {
  const { discount, discountText } = calculatePlanDiscount(
    total,
    coupon,
    planServiceDb,
    durationMonths,
  );
  const totalWithDiscount = total - discount;

  return {
    discount,
    discountText,
    totalWithDiscount,
  };
};

export function getUpdatedPlanPrices(
  basePrices: BasePrices,
  discounts: Discounts,
): PlanPrices {
  const updatedPlans: Partial<PlanPrices> = {};
  (Object.keys(basePrices) as Plan[]).forEach((plan) => {
    const updatedPeriods: Partial<PlanPricing> = {};
    (Object.keys(discounts[plan]) as BillingPeriod[]).forEach((period) => {
      const discountByPeriod = discounts[plan][period];
      const planPrice = basePrices[plan];
      updatedPeriods[period] = {
        extraRegimen: calculatePriceWithDiscount(
          ADDON_PRICES.extraRegimen,
          discountByPeriod,
        ),
        discount: calculateDiscount(discountByPeriod),
        price:
          period === 'monthly'
            ? planPrice
            : calculatePriceWithDiscount(planPrice, discountByPeriod),
      };
    });

    updatedPlans[plan] = updatedPeriods as PlanPricing;
  });

  return updatedPlans as PlanPrices;
}

export function getInformationTexts(
  planPrices: PlanPrices,
  badgeText: BadgeText,
): PlanInformationTexts {
  const informationTexts: Partial<PlanInformationTexts> = {};
  (Object.keys(planPrices) as Plan[]).forEach((plan) => {
    const periods: Partial<InformationTexts> = {};
    (Object.keys(badgeText) as BillingPeriod[]).forEach((period) => {
      periods[period] = [
        `Base: $${planPrices[plan][period].price}${badgeText.monthly}`,
        `1 Régimen: ${planPrices[plan][period].extraRegimen} c/u ${badgeText.monthly}`,
      ];
    });
    informationTexts[plan] = periods as InformationTexts;
  });

  return informationTexts as PlanInformationTexts;
}
