import {
  TAXABLE_ENTITY_UPDATE,
  TAXABLE_ENTITY_UPDATE_SUCCESS,
  TAXABLE_ENTITY_UPDATE_FAILURE,
  TAXABLE_ENTITY_FIEL_UPLOAD,
  TAXABLE_ENTITY_FIEL_UPLOAD_SUCCESS,
  TAXABLE_ENTITY_FIEL_UPLOAD_FAILURE,
  TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD,
  TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_SUCCESS,
  TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_FAILURE,
  TAXABLE_ENTITY_CSD_UPLOAD,
  TAXABLE_ENTITY_CSD_UPLOAD_SUCCESS,
  TAXABLE_ENTITY_CSD_UPLOAD_FAILURE,
  TAXABLE_ENTITY_CREDENTIALS_FETCH,
  TAXABLE_ENTITY_CREDENTIALS_FETCH_SUCCESS,
  TAXABLE_ENTITY_CREDENTIALS_FETCH_FAILURE,
} from '@constants/actionTypes';

export const updateTaxableEntity = (taxable_entity, id, options, callback) => ({
  type: TAXABLE_ENTITY_UPDATE,
  payload: { taxable_entity, id, options, callback },
});
export const updateTaxableEntitySuccess = (taxable_entity) => ({
  type: TAXABLE_ENTITY_UPDATE_SUCCESS,
  payload: taxable_entity,
});
export const updateTaxableEntityFailure = () => ({
  type: TAXABLE_ENTITY_UPDATE_FAILURE,
});
export const uploadTaxableEntityFiel = (formdata) => ({
  type: TAXABLE_ENTITY_FIEL_UPLOAD,
  payload: { formdata },
});
export const uploadTaxableEntityFielSuccess = (taxable_entity) => ({
  type: TAXABLE_ENTITY_FIEL_UPLOAD_SUCCESS,
  payload: taxable_entity,
});
export const uploadTaxableEntityFielFailure = () => ({
  type: TAXABLE_ENTITY_FIEL_UPLOAD_FAILURE,
});
export const uploadTaxableEntityPayrollCredentials = (formdata) => ({
  type: TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD,
  payload: { formdata },
});
export const uploadTaxableEntityPayrollCredentialsSuccess = (
  taxable_entity
) => ({
  type: TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_SUCCESS,
  payload: taxable_entity,
});
export const uploadTaxableEntityPayrollCredentialsFailure = () => ({
  type: TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD_FAILURE,
});
export const uploadTaxableEntityCsd = (formdata) => ({
  type: TAXABLE_ENTITY_CSD_UPLOAD,
  payload: { formdata },
});
export const uploadTaxableEntityCsdSuccess = (taxable_entity) => ({
  type: TAXABLE_ENTITY_CSD_UPLOAD_SUCCESS,
  payload: taxable_entity,
});
export const uploadTaxableEntityCsdFailure = () => ({
  type: TAXABLE_ENTITY_CSD_UPLOAD_FAILURE,
});

export const fetchTaxableEntityCredentials = (taxable_entity) => ({
  type: TAXABLE_ENTITY_CREDENTIALS_FETCH,
  payload: { taxable_entity },
});
export const fetchTaxableEntityCredentialsSuccess = (credentials) => ({
  type: TAXABLE_ENTITY_CREDENTIALS_FETCH_SUCCESS,
  payload: credentials,
});
export const fetchTaxableEntityCredentialsFailure = () => ({
  type: TAXABLE_ENTITY_CREDENTIALS_FETCH_FAILURE,
});
