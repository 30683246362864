import useAnnualDeductionLimits from '@hooks/useAnnualDeductionLimits';
import useUpdateAnnualDeductions from '@hooks/useUpdateAnnualDeductions';
import { Calendar02OutlineIcon } from '@konta/icons';
import { config, useMediaQuery, Text } from '@konta/ui';
import toCurrency from '@util/toCurrency';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useToggle } from 'rooks';
import { ProgressBarSection } from 'shared/components/ActionableMetricCard/ActionableMetricCard';
import { AnnualDeductionCategoryKey, SelectedCategory } from 'types/entities';

interface UpdatedValues {
  id: number;
  period: string;
  newValue: number;
}

const categoryImportantOrder: AnnualDeductionCategoryKey[] = [
  'health',
  'funeral_expenses',
  'donations',
  'mortgage_interest',
  'retirement_fund',
  'insurance',
  'school_transportation',
  'education',
];

export default function useDeductionDetails() {
  const { annualDeduction, annualDeductionLoading } =
    useAnnualDeductionLimits();

  const updateDeclarationBalance = useUpdateAnnualDeductions();

  const [tab, setTab] = useState<'categories' | 'limits'>();
  const [openConfirmModal, toggleOpenConfirmModal] = useToggle();
  const [updatedValues, setUpdatedValues] = useState<UpdatedValues | null>();

  const [selectedPeriodDate, setSelectedPeriodDate] = useState<string | null>(
    null,
  );
  const [selectedCategory, setSelectedCategory] =
    useState<SelectedCategory | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openModalCfdisTable, toggleOpenModalCfdisTable] = useToggle();
  const [openModalCategoryDescription, toggleOpenModalCategoryDescription] =
    useToggle();

  const labelSquareColor = useMemo(
    () => ({
      health: '$orangedark400',
      education: '$grayblue400',
      mortgage_interest: '$green500',
      retirement_fund: '$pink500',
      donations: '$greenlight400',
      insurance: '$primary500',
      funeral_expenses: '$bluelight500',
      school_transportation: '$fuchsia500',
    }),
    [],
  );

  const labelName = useMemo(
    () => ({
      health: 'Gastos médicos y dentales',
      education: 'Educación',
      mortgage_interest: 'Intereses de créditos hipotecarios',
      retirement_fund: 'Aportaciones al retiro',
      donations: 'Donativos',
      insurance: 'Seguros',
      funeral_expenses: 'Gastos funerarios',
      school_transportation: 'Transporte escolar',
    }),
    [],
  );
  const labelDescription = useMemo(
    () => ({
      health:
        'Incluyen los costos de servicios médicos, dentales y hospitalarios. Son deducibles si son proporcionados por profesionales autorizados y si se cuenta con la documentación fiscal adecuada (como una factura electrónica o "CFDI"). Los medicamentos solo son deducibles si están incluidos en las facturas del hospital.',
      education:
        'Las colegiaturas desde preescolar hasta preparatoria (o niveles equivalentes) son deducibles, sujetas a límites legales. Sin embargo, otros gastos relacionados con la educación, como uniformes o transporte escolar, no son deducibles.',
      mortgage_interest:
        'Los intereses reales pagados sobre préstamos hipotecarios utilizados para comprar una vivienda son deducibles. El contribuyente debe contar con un certificado de intereses emitido por la institución financiera que otorga el préstamo.',
      retirement_fund:
        'Son deducibles los aportes que se realicen a cuentas personales de retiro registradas en la Comisión Nacional del Sistema de Ahorro para el Retiro (CONSAR), pero hasta un límite anual determinado.',
      donations:
        'Son deducibles los donativos que se realicen a instituciones autorizadas, sin fines de lucro y sin reciprocidad, siempre que no excedan el límite legal.',
      insurance:
        'Son deducibles las primas del seguro médico del contribuyente, su cónyuge/pareja y sus ascendientes o descendientes directos, siempre que dichas personas dependan económicamente del contribuyente.',
      funeral_expenses:
        'Son deducibles los gastos funerarios, pero están sujetos a un límite máximo anual que fija la normatividad fiscal.',
      school_transportation:
        'Son deducibles las primas del seguro médico del contribuyente, su cónyuge/pareja y sus ascendientes o descendientes directos, siempre que dichas personas dependan económicamente del contribuyente.',
    }),
    [],
  );

  const toggleIsSidebarOpen = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  const isMobile = useMediaQuery(config.media['<xs']);

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const mothsLimitsAccordionData = useMemo(() => {
    const estimates = annualDeduction?.monthly_income_estimates
      ?.map((estimate, index) => {
        const estimateDate = dayjs()
          .month(estimate.month_period - 1)
          .startOf('month')
          .format('YYYY-MM-DD');
        return {
          ...estimate,
          value: +estimate.amount,
          label: capitalizeFirstLetter(
            dayjs()
              .month(estimate.month_period - 1)
              .startOf('month')
              .format('MMMM'),
          ),
          leftIcon: Calendar02OutlineIcon,
          actionValue: () => {
            setSelectedPeriodDate(estimateDate);
            toggleOpenModalCfdisTable();
          },
          canEditValue: estimate.status === 'open',
          onSaveValue: (newValue: number) => {
            // validate if the value has changed
            if (newValue === +estimate.amount) {
              return;
            }
            setUpdatedValues(() => ({
              id: estimate.id,
              period: estimateDate,
              newValue,
            }));
            toggleOpenConfirmModal();
          },
          ...(index === 0 && {
            intercomTarget: 'deduction-limit-invoice-trigger',
          }),
        };
      })
      .sort((a, b) => a.month_period - b.month_period);
    return [
      {
        label: `Total de ingresos`,
        value: annualDeduction?.income_accumulated ?? 0,
        children: estimates ?? [],
      },
      {
        label: `(${annualDeduction?.umas ?? 0}) UMAS`,
        value: annualDeduction?.umas_amount ?? 0,
        underlineValue: true,
        labelTooltip: 'Unidad de Medida y Actualización',
      },
      {
        label: `Total de ingresos (15%)`,
        value: annualDeduction?.income_deduction ?? 0,
      },
      {
        label: `Límite de tus deducciones`,
        value: annualDeduction?.limit ?? 0,
      },
    ];
  }, [toggleOpenModalCfdisTable, toggleOpenConfirmModal, annualDeduction]);

  const progressBarSections = useMemo<ProgressBarSection[]>(() => {
    if (!annualDeduction) return [];

    const categories = annualDeduction.annual_deduction_categories;

    const deductionImportantCategories = categoryImportantOrder
      .filter((key) => categories[key])
      .map((key) => ({
        key,
        ...categories[key],
      }));

    return deductionImportantCategories.map(({ key, amount }) => {
      const color = labelSquareColor[key] || '$gray200';
      const label = labelName[key] || '';

      return {
        value: +(amount ?? 0),
        color,
        label,
        isCurrency: true,
      };
    });
  }, [annualDeduction, labelSquareColor, labelName]);

  const globalLimit = annualDeduction?.limit ?? 0;
  const accumulated = annualDeduction?.deduction_accumulated ?? 0;

  const availableForDeduction = Math.max(+globalLimit - +accumulated, 0);

  const categoriesAccordionData = useMemo(
    () =>
      Object.entries(annualDeduction?.annual_deduction_categories ?? {})
        .sort(
          ([keyA], [keyB]) =>
            categoryImportantOrder.indexOf(keyA as AnnualDeductionCategoryKey) -
            categoryImportantOrder.indexOf(keyB as AnnualDeductionCategoryKey),
        )
        .map(([key, value]) => {
          const subCategories = value?.subcategories.map((subcategory) => ({
            label: subcategory.name,
            value: subcategory.amount,
            description: (
              <Text
                css={{
                  fontSize: '$2xs',
                  fontWeight: '500',
                  lineHeight: '18px',
                  color: '$grayblue700',
                }}
              >
                Límite:{' '}
                <Text css={{ fontWeight: '600' }}>
                  {toCurrency(+(subcategory.limit ?? 0))}
                </Text>
              </Text>
            ),
          }));
          const category = {
            category: key as AnnualDeductionCategoryKey,
            name: value?.name ?? '',
            amount: value?.amount ?? 0,
            limit: value?.limit ?? 0,
            subcategories: value?.subcategories ?? [],
          };

          return {
            label: labelName[key as keyof typeof labelName],
            value: value?.amount ?? 0,
            labelSquareColor:
              labelSquareColor[key as keyof typeof labelSquareColor],
            actionValue: () => {
              setSelectedCategory(category);
              toggleOpenModalCfdisTable();
            },
            onLabelClick: () => {
              setSelectedCategory(category);
              toggleOpenModalCategoryDescription();
            },
            children: subCategories,
          };
        }),
    [
      annualDeduction,
      toggleOpenModalCfdisTable,
      labelName,
      labelSquareColor,
      toggleOpenModalCategoryDescription,
    ],
  );

  const isLoading =
    annualDeductionLoading || updateDeclarationBalance.isLoading;

  const handleUpdateLimits = useCallback(async () => {
    if (!updatedValues) return;
    const payload = {
      monthly_income_estimates_attributes: [
        {
          id: updatedValues.id,
          amount: updatedValues.newValue,
        },
      ],
    };
    await updateDeclarationBalance.mutateAsync(payload);
    toggleOpenConfirmModal();
  }, [updatedValues, updateDeclarationBalance, toggleOpenConfirmModal]);

  const invoiceModalTitle = useMemo(() => {
    if (selectedPeriodDate) {
      return capitalizeFirstLetter(
        dayjs(selectedPeriodDate).format('MMMM YYYY'),
      );
    }
    return selectedCategory?.name ?? 'Comprobantes de Ingresos';
  }, [selectedPeriodDate, selectedCategory]);

  const isLimitFull = globalLimit > 0 && availableForDeduction === 0;

  return {
    globalLimit,
    accumulated,
    progressBarSections,
    availableForDeduction,
    categoriesAccordionData,
    mothsLimitsAccordionData,
    tab,
    setTab,
    isMobile,
    isSidebarOpen,
    toggleIsSidebarOpen,
    openModalCfdisTable,
    toggleOpenModalCfdisTable,
    selectedPeriodDate,
    setSelectedPeriodDate,
    selectedCategory,
    setSelectedCategory,
    capitalizeFirstLetter,
    updatedValues,
    setUpdatedValues,
    openConfirmModal,
    toggleOpenConfirmModal,
    isLoading,
    handleUpdateLimits,
    invoiceModalTitle,
    annualDeduction,
    isLimitFull,
    openModalCategoryDescription,
    toggleOpenModalCategoryDescription,
    labelName,
    labelDescription,
  };
}
