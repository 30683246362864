import { ComponentProps, ReactNode } from 'react';
import { Button } from '@konta/ui';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import useNextPhase from '@hooks/useNextPhase';

const defaultPayload = {
  workflow: {
    next_phase: 'primary_phase',
  },
};

interface PrimaryPhaseButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  payload?: Record<string, unknown>;
}

export default function PrimaryPhaseButton({
  children,
  disabled = false,
  loading = false,
  onClick,
  payload = defaultPayload,
  variant = 'contained',
  ...props
}: PrimaryPhaseButtonProps) {
  const nextPhase = useNextPhase();
  const { loading: loadingWorkflow } = useSelectedWorkflow();

  const handleClick = () => {
    nextPhase(payload);
  };

  return (
    <Button
      color="primary"
      data-testid="primary-phase"
      disabled={disabled}
      loading={loadingWorkflow || loading}
      onClick={onClick || handleClick}
      variant={variant}
      {...props}
    >
      {children || 'Continuar'}
    </Button>
  );
}
