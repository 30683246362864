import { ReactNode, useMemo, useState } from 'react';
import { useMediaQuery } from '@konta/ui';
import useSubscriptions from '@hooks/useSubscriptions';
import subscriptionService from 'shared/services/subscription';
import type { Plan as PlanData } from '@konta/ui/dist/components/Plans/types';
import { BillingPeriod, Plan, PricesData } from 'types/subscription';
import { FeatureSection } from './types';

const FUNCTIONALITIES: FeatureSection = {
  title: 'Funcionalidades',
  id: 'functionalities',
  features: [
    {
      title:
        'Aclaraciones necesarias para la aprobación y presentación ante el SAT.',
      value: true,
      type: 'feature',
    },
    { title: 'Diagnóstico fiscal gratis', value: true, type: 'feature' },
    { title: 'Facturación ilimitada', value: true, type: 'feature' },
    { title: 'Movimientos ilimitados', value: true, type: 'feature' },
    { title: 'Konta Estatus', value: true, type: 'feature' },
  ],
};

const createFeatures = (
  billingPeriodicity: string,
  planPrices: PricesData,
  badgeText: string,
  [customPlan0, customPlan1]: boolean[],
): FeatureSection[] => [
  {
    title: `Detalle de ${billingPeriodicity}`,
    id: 'periodDetail',
    features: [
      {
        title: `Costo base mensual`,
        value: `$${planPrices.price} + IVA`,
        type: 'feature',
      },
      {
        title: '+1 Régimen',
        value: `$${planPrices.extraRegimen}c/u ${badgeText}`,
        type: 'feature',
      },
    ],
  },
  {
    ...FUNCTIONALITIES,
    features: [
      {
        title: 'Asesor personalizado',
        value: customPlan0,
        type: 'feature',
      },
      {
        title:
          'Conciliación de tu estado de cuenta con las facturas emitidas y presentación de tus impuestos.',
        value: customPlan1,
        type: 'feature',
      },
      ...FUNCTIONALITIES.features,
    ],
  },
];

const createPlan = (
  id: Plan,
  name: string,
  supportingText: string,
  billingPeriod: BillingPeriod,
  planPrices: PricesData,
  badgeText: string,
  features: FeatureSection[],
  informationTexts: string[],
  mobileFeatures: (string | ReactNode)[],
  isMostPopular = false,
): PlanData => ({
  textBeforePrice: 'Desde',
  id,
  name,
  badgeText,
  supportingText,
  billingPeriod,
  pricing: {
    price: planPrices.price,
    discount: planPrices.discount,
  },
  isCurrentPlan: false,
  isMostPopular,
  mobileFeatures,
  features,
  informationTexts,
});

const getMobileFeatures = (featuresArray: FeatureSection[]) =>
  featuresArray
    .filter(({ id }) => id === 'functionalities')
    .flatMap(({ features }) =>
      features.filter(({ value }) => Boolean(value)).map(({ title }) => title),
    );

const { BADGE_TEXT, BILLING_PERIODICITY_TEXT, INFORMATION_TEXTS, PLAN_PRICES } =
  subscriptionService.constants;

export default function usePricing() {
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>('monthly');
  const isSmall = useMediaQuery('(max-width: 1020px)');
  const isTablet = useMediaQuery('(max-width: 768px)');

  const { handlePlanSelect } = useSubscriptions() as {
    handlePlanSelect: (
      planId: string,
      plans: PlanData[],
      billingPeriod: string,
    ) => void;
  };

  const plans = useMemo<PlanData[]>(() => {
    const billingPeriodicity = BILLING_PERIODICITY_TEXT[billingPeriod];
    const badgeText = BADGE_TEXT.monthly;
    const assistedFeatures = createFeatures(
      billingPeriodicity,
      PLAN_PRICES.assisted_plan[billingPeriod],
      badgeText,
      [false, false],
    );
    const customFeatures = createFeatures(
      billingPeriodicity,
      PLAN_PRICES.personalized_plan[billingPeriod],
      badgeText,
      [true, true],
    );
    const assistedMobileFeatures = getMobileFeatures(assistedFeatures);
    const customMobileFeatures = getMobileFeatures(customFeatures);
    const diffBetweenMobileFeatures = customMobileFeatures.filter(
      (feature) => !assistedMobileFeatures.includes(feature),
    );
    return [
      createPlan(
        'assisted_plan',
        'Asistido',
        'Un experto te ayudará a hacer tus impuestos',
        billingPeriod,
        PLAN_PRICES.assisted_plan[billingPeriod],
        badgeText,
        assistedFeatures,
        INFORMATION_TEXTS.assisted_plan[billingPeriod],
        assistedMobileFeatures,
      ),
      createPlan(
        'personalized_plan',
        'Personalizado',
        'Nosotros hacemos los impuestos por ti',
        billingPeriod,
        PLAN_PRICES.personalized_plan[billingPeriod],
        badgeText,
        customFeatures,
        INFORMATION_TEXTS.personalized_plan[billingPeriod],
        [
          <b key="allPlanBasic">Todo lo del plan Asistido +</b>,
          ...diffBetweenMobileFeatures,
        ],
      ),
    ];
  }, [billingPeriod]);

  return {
    billingPeriod,
    setBillingPeriod,
    isSmall,
    isTablet,
    plans,
    handlePlanSelect,
  };
}
