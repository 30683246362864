import {
  StatusMap,
  WorkflowPhaseKeyRouteType,
  WorkflowPhaseKeyType,
  WorkflowPhases,
} from 'types/workflow';
import {
  Bell04LineIcon,
  CalculatorLineIcon,
  CheckLineIcon,
  CurrencyDollarCircleLineIcon,
  FileSearch02LineIcon,
  FileUploadOuLc1OutlineIcon,
  Flag5OutlineIcon,
} from '@konta/icons';

export const WORKFLOW_ROUTE = '/app/workflow/:workflowId';
export const WORKFLOW_ROUTES: WorkflowPhases = {
  [WorkflowPhaseKeyType.Intro]: WorkflowPhaseKeyRouteType.Intro,

  [WorkflowPhaseKeyType.DeclarationInfo]:
    WorkflowPhaseKeyRouteType.DeclarationInfo,

  [WorkflowPhaseKeyType.IncomesByRegime]:
    WorkflowPhaseKeyRouteType.IncomesByRegime,

  [WorkflowPhaseKeyType.ExpensesByRegime]:
    WorkflowPhaseKeyRouteType.ExpensesByRegime,

  [WorkflowPhaseKeyType.ResumeDeclaration]:
    WorkflowPhaseKeyRouteType.ResumeDeclaration,

  [WorkflowPhaseKeyType.PendingDeclaration]:
    WorkflowPhaseKeyRouteType.PendingDeclaration,

  [WorkflowPhaseKeyType.CaptureLinePayment]:
    WorkflowPhaseKeyRouteType.CaptureLinePayment,

  [WorkflowPhaseKeyType.TaxesPayment]: WorkflowPhaseKeyRouteType.TaxesPayment,
};

export const WORKFLOW_STATUS_DATA: StatusMap = {
  fetching_invoice: {
    IconComponent: FileSearch02LineIcon,
    title: 'Obteniendo facturas',
    description: 'En estos momentos estamos preparando tu declaración',
  },
  classifying: {
    IconComponent: FileSearch02LineIcon,
    title: 'Clasificando facturas',
    description: 'En estos momentos estamos preparando tu declaración',
  },
  ready: {
    IconComponent: Flag5OutlineIcon,
    title: 'Lista para empezar',
    description: 'Ya puedes comenzar tu declaración',
    btnText: 'Iniciar',
  },
  in_proccess: {
    IconComponent: Bell04LineIcon,
    title: 'En proceso',
    description: 'La declaración ya ha iniciado, continúela',
  },
  verify_declaration: {
    IconComponent: CalculatorLineIcon,
    title: 'Verificando declaración',
    description: 'Un contador está verificando tu declaración 🧐',
  },
  submit_declaration: {
    IconComponent: FileUploadOuLc1OutlineIcon,
    title: 'Presentando declaración',
    description: 'La declaración está siendo presentada 🗂',
  },
  pending_payment: {
    IconComponent: CurrencyDollarCircleLineIcon,
    title: 'Pendiente de pago',
    description: 'Su declaración necesita ser pagada 🤗',
    btnText: 'Ir a pagar',
  },
  done: {
    IconComponent: CheckLineIcon,
    title: '¡Acabaste! 🥳',
    description:
      '¡Felicidades! Tómate un café y nos vemos el siguiente mes. 🚀',
  },
  paid: {
    IconComponent: CurrencyDollarCircleLineIcon,
    title: 'Pending',
    description: 'Pending',
  },
  bank_payment: {
    IconComponent: CurrencyDollarCircleLineIcon,
    title: 'Pending',
    description: 'Pending',
  },
};
