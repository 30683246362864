import { Flex, Text, styled } from '@konta/ui';
import { ArrowRightLineIcon } from '@konta/icons';
import WorkflowsLayout from '@components/WorkflowsLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import toCurrency from '@util/toCurrency';
import PrimaryPhaseButton from '../../PrimaryPhaseButton';
import MetricsTotalCard from '../../MetricsTotalCard';
import useResumeDeclaration from './useResumeDeclaration';

const TotalNextPeriodIva = styled('div', {
  display: 'flex',
  gap: '$12',
  alignItems: 'center',
  justifyContent: 'end',
  padding: '$12',
  flex: 1,
  '@md': {
    padding: '$24',
  },
});

export default function ResumeDeclaration() {
  const {
    payrollTaxesRow,
    hasPayrollRetentions,
    rowsByDeclaration,
    totalNextPeriodIsr,
    totalNextPeriodIva,
    balanceTaxesRow,
    isNextBtnEnabled,
  } = useResumeDeclaration();

  return (
    <WorkflowsLayout
      actions={
        <>
          <PrevPhaseButton />
          <TotalNextPeriodIva>
            <Flex column itemsEnd>
              <Text lineHeight="l" l>
                {toCurrency(totalNextPeriodIva)}
              </Text>
              <Text>IVA a favor para el siguiente periodo</Text>
            </Flex>
            {+totalNextPeriodIsr > 0 && (
              <Flex column itemsEnd>
                <Text lineHeight="l" l>
                  {toCurrency(+totalNextPeriodIsr)}
                </Text>
                <Text>ISR a favor para el siguiente periodo</Text>
              </Flex>
            )}
          </TotalNextPeriodIva>
          <PrimaryPhaseButton
            disabled={!isNextBtnEnabled}
            rightIcon={<ArrowRightLineIcon />}
          >
            Finalizar
          </PrimaryPhaseButton>
        </>
      }
      title="Declaración mensual"
    >
      <Flex column gap={12}>
        <Text x3l bold>
          Resumen de la declaración
        </Text>
        <Flex column gap={8}>
          <Flex
            gap={10}
            css={{
              '@<sm': {
                gap: 20,
                flexDirection: 'column',
                flexWrap: 'wrap',
              },
            }}
          >
            {rowsByDeclaration?.map(({ name, rows }) => (
              <MetricsTotalCard
                key={name}
                striped="even"
                title={`Cálculo de ${name}`}
                content=""
                rows={rows}
              />
            ))}
          </Flex>
          <MetricsTotalCard
            striped="odd"
            title="Balance de impuestos"
            content=""
            rows={balanceTaxesRow}
          />
          {hasPayrollRetentions && (
            <MetricsTotalCard
              striped="even"
              title="Sueldos y salarios"
              content=""
              rows={payrollTaxesRow}
            />
          )}
        </Flex>
      </Flex>
    </WorkflowsLayout>
  );
}
