import { useState } from 'react';
import _ from 'lodash';
import { Box, Button, Flex, Text } from '@konta/ui';
import { AnnualDeclaration, ProcessType } from 'types/entities';
import useProcess from '@hooks/useProcess';
import NextStepButton from '@components/NextStepButton';
import Loader from '@components/Loader';
import { useToggle } from 'usehooks-ts';
import RequestAdjustmentModal from './RequestAdjustmentModal';
import RegimeAnnualResume from './RegimeAnnualResume';
import { Heading } from './RegimeAnnualResume/styled';
import EmptyState from './EmptyState/EmptyState';

interface ResumeAnnualProps {
  onHandleResumeByRegime: (
    satKeys: number[],
    annualDeclaration: AnnualDeclaration,
  ) => void;
  isLoading?: boolean;
  currentAnnualDeclarations: AnnualDeclaration[];
  period: number;
  customPeriod?: boolean;
}

export default function ResumeAnnual({
  onHandleResumeByRegime,
  isLoading,
  currentAnnualDeclarations,
  period,
  customPeriod,
}: ResumeAnnualProps) {
  const [payload, setPayload] = useState<boolean>();
  const { activeStep } = useProcess(ProcessType.AnnualDeclarationProcess);
  const process = ProcessType.AnnualDeclarationProcess;
  const [openDisagreeModal, toggleDisagreeModal] = useToggle(false);
  const answers = activeStep?.answers || {};
  const hasAnswers =
    _.isPlainObject(answers) && Object.keys(answers).length > 0;

  if (isLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  const hasDeclarations = currentAnnualDeclarations.length > 0;

  return (
    <>
      <RequestAdjustmentModal
        isOpen={openDisagreeModal}
        toggleIsOpen={toggleDisagreeModal}
      />
      <Flex column>
        <Heading>
          <Text xl bold>
            Declaración del año {period}
          </Text>
        </Heading>
        {!hasDeclarations ? (
          <EmptyState />
        ) : (
          currentAnnualDeclarations.map((currentAnnualDeclaration) => (
            <RegimeAnnualResume
              period={period}
              key={currentAnnualDeclaration.id}
              onHandleResumeByRegime={onHandleResumeByRegime}
              isWorkflowsLoading={isLoading}
              currentAnnualDeclaration={currentAnnualDeclaration}
            />
          ))
        )}
      </Flex>
      <Flex justify="end" gap={12}>
        {!customPeriod && (
          <>
            <Button
              color="gray"
              variant="outlined"
              disabled={hasAnswers || !hasDeclarations}
              onClick={toggleDisagreeModal}
            >
              {hasAnswers ? 'Notificación enviada' : 'Solicitar un ajuste'}
            </Button>
            <NextStepButton
              currentProcessType={process}
              data-cy="resumeAnnualNextBtn"
              text="Continuar"
              payload={payload}
              onClick={() => setPayload(true)}
            />
          </>
        )}
      </Flex>
    </>
  );
}
