import React, { useState, useMemo } from 'react';
import { Card, CardBody, Collapse, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Colxx } from '@components';
import { Flex } from '@konta/ui';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import UserPlans from '@components/UserPlans';
import UserSubscription from '@components/UserSubscription';
import { useTaxableEntity } from '@hooks';
import { OnboardingVideo } from './OnboardingSteps';
import { ReactComponent as OnboardingIcon } from './img/onboarding.svg';
import { ReactComponent as OnboardingCheckIcon } from './img/onboarding-check.svg';

export default function OnboardingInformationCollapse({
  selectedAccordion,
  handleAccordionClick,
}) {
  const { taxable_entity } = useTaxableEntity();
  const [selectedStep, setSelectedStep] = useState(0);

  const statusBarWidths = useMemo(() => {
    const total_steps = 2;
    let total_complete = 0;
    if (taxable_entity.onboarding_detail?.intro_video) {
      total_complete += 1;
    }
    if (taxable_entity.status !== 'demo') {
      total_complete += 1;
    }
    const widthComplete = (total_complete / total_steps) * 100;
    const widthIncomplete = (1 - total_complete / total_steps) * 100;

    return { complete: `${widthComplete}%`, incomplete: `${widthIncomplete}%` };
  }, [taxable_entity.onboarding_detail?.intro_video, taxable_entity.status]);

  const handleStepClick = (step) => {
    if (selectedStep === step) {
      setSelectedStep(0);
    } else {
      setSelectedStep(step);
    }
  };

  const cardBodyClassNames = cx('py-4 onboarding-button', {
    'onboarding-complete-step':
      statusBarWidths.complete === '100%' && selectedAccordion !== 1,
  });

  const firstCardFistStepClassNames = cx('onboarding-step-wrap mb-2', {
    'onboarding-step-wrap-selected': selectedStep === 1,
  });
  const firstCardSecondStepClassNames = cx('onboarding-step-wrap mb-2', {
    'onboarding-step-wrap-selected': selectedStep === 2,
  });

  return (
    <Card className="mb-4 onboarding-card">
      <CardBody
        className={cardBodyClassNames}
        tabIndex={0}
        role="button"
        onClick={() => handleAccordionClick(1)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleAccordionClick(1);
          }
        }}
      >
        <Row>
          <Colxx md={10}>
            <div className="d-flex align-items-center">
              <div className="mr-3 onboarding-icon">
                <OnboardingIcon />
                {statusBarWidths.complete === '100%' && (
                  <span className="mr-3 text-center center-layout onboarding-step-completed onboarding-complete">
                    <OnboardingCheckIcon className="onboarding-check" />
                  </span>
                )}
              </div>
              <div>
                <h3 className="mb-0 d-flex align-items-center">
                  1.- Sácale todo el provecho a tu cuenta
                  <span className="badge badge-outline-primary small-badge ml-2">
                    Obligatorio
                  </span>
                </h3>
              </div>
            </div>
          </Colxx>
          <Colxx md={2}>
            <Flex className="h-100" justify="end" align="center">
              {statusBarWidths.complete === '100%' ? (
                <p className="mb-0 onboarding-step-text-success">
                  ¡Todo completado!
                </p>
              ) : (
                <p className="mb-0">5 min</p>
              )}
            </Flex>
          </Colxx>
        </Row>
      </CardBody>
      <div className="d-flex onboarding-progress-bar">
        <div
          className="d-flex onboarding-progress-bar-complete"
          style={{ width: statusBarWidths.complete }}
        />
        <div
          className="d-flex onboarding-progress-bar-incomplete"
          style={{ width: statusBarWidths.incomplete }}
        />
      </div>
      <Collapse isOpen={selectedAccordion === 1} className="p-4">
        {/* First step */}
        <div className={firstCardFistStepClassNames}>
          <div
            tabIndex={0}
            role="button"
            className="d-flex align-items-center onboarding-step-button-wrap"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleStepClick(1);
              }
            }}
            onClick={() => handleStepClick(1)}
          >
            {taxable_entity.onboarding_detail?.intro_video ? (
              <span className="mr-3 text-center center-layout onboarding-step-completed">
                <div>
                  <OnboardingCheckIcon className="onboarding-check" />
                </div>
              </span>
            ) : (
              <span className="mr-3 d-flex onboarding-step-circle center-layout text-center">
                <div>1</div>
              </span>
            )}
            <div className="onboarding-icon-step">
              <h3 className="mb-0">Ve todo lo que puede hacer con Konta</h3>
            </div>
            <Flex>
              <ChevronRightIcon />
            </Flex>
          </div>
          <Collapse isOpen={selectedStep === 1}>
            {selectedStep === 1 && (
              <OnboardingVideo
                selectedAccordion={selectedStep}
                handleStepClick={handleStepClick}
              />
            )}
          </Collapse>
        </div>
        {/* Second step */}
        <div className={firstCardSecondStepClassNames}>
          <div
            tabIndex={0}
            role="button"
            className="d-flex align-items-center onboarding-step-button-wrap"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleStepClick(2);
              }
            }}
            onClick={() => handleStepClick(2)}
          >
            {taxable_entity.status !== 'demo' ? (
              <span className="mr-3 text-center center-layout onboarding-step-completed">
                <div>
                  <OnboardingCheckIcon className="onboarding-check" />
                </div>
              </span>
            ) : (
              <span className="mr-3 d-felx onboarding-step-circle center-layout text-center">
                <div>2</div>
              </span>
            )}
            <div className="onboarding-icon-step">
              <h3 className="mb-0">Contratar un plan</h3>
            </div>
            <Flex>
              <ChevronRightIcon />
            </Flex>
          </div>
          <Collapse isOpen={selectedStep === 2}>
            {taxable_entity.status === 'demo' ? (
              <UserPlans asCards />
            ) : (
              <Flex className="p-2 p-sm-5">
                <UserSubscription />
              </Flex>
            )}
          </Collapse>
        </div>
      </Collapse>
    </Card>
  );
}

OnboardingInformationCollapse.propTypes = {
  selectedAccordion: PropTypes.number.isRequired,
  handleAccordionClick: PropTypes.func.isRequired,
};
