import dayjs from 'dayjs';
import { useMemo } from 'react';
import _ from 'lodash';
import useDeclarations from '@hooks/useDeclarations';
import useAnnualExpenses from '@hooks/useAnnualExpenses';
import useAnnualIncomes from '@hooks/useAnnualIncomeTotals';
import { RESICO_SAT_KEY } from 'shared/constants/grids';
import type { AnnualDeclaration } from 'types/entities';

interface UseRegimeAnnualResumeProps {
  currentAnnualDeclaration: AnnualDeclaration;
  period: number;
}
export default function useRegimeAnnualResume({
  currentAnnualDeclaration,
  period,
}: UseRegimeAnnualResumeProps) {
  const { declarations, declarationsLoading } = useDeclarations({
    params: {
      'q[start_date_gteq]': `${period}-01-01`,
      'q[end_date_lteq]': `${period}-12-31`,
      'q[is_active_true]': 1,
    },
  });

  const hasResico = useMemo(
    () => currentAnnualDeclaration?.fiscal_regime?.sat_key === RESICO_SAT_KEY,
    [currentAnnualDeclaration],
  );

  const hasSalaries = useMemo(
    () =>
      currentAnnualDeclaration?.annual_declaration_totals?.some(
        (total) => total.source_type === 'salary',
      ),
    [currentAnnualDeclaration],
  );

  const hasPfae = useMemo(
    () =>
      currentAnnualDeclaration?.annual_declaration_totals?.some(
        (total) => total.source_type === 'business_activity',
      ),
    [currentAnnualDeclaration],
  );
  const hasPlatforms = useMemo(
    () =>
      currentAnnualDeclaration?.annual_declaration_totals?.some(
        (total) => total.source_type === 'digital_platform',
      ),
    [currentAnnualDeclaration],
  );

  const annualTotals = useMemo(
    () => currentAnnualDeclaration?.annual_declaration_totals || [],
    [currentAnnualDeclaration],
  );

  const totalExpenses = useAnnualExpenses(annualTotals).find(
    (row) => row.id === 'totalExpenses',
  )?.value as number;
  const totalExpensesResico = useAnnualExpenses(annualTotals).find(
    (row) => row.id === 'expensesResico',
  )?.value as number;

  const totalIncomes = useAnnualIncomes(annualTotals).find(
    (row) => row.id === 'totalIncomes',
  )?.value as number;
  const totalIncomesResico = useAnnualIncomes(annualTotals).find(
    (row) => row.id === 'incomesResico',
  )?.value as number;
  const totalIncomesSalaries = useAnnualIncomes(annualTotals).find(
    (row) => row.id === 'incomesBySalary',
  )?.value as number;
  const profitOrLossResico = +(currentAnnualDeclaration?.profit_or_loss || 0);
  const profitOrLoss = +(currentAnnualDeclaration?.profit_or_loss || 0);

  const totals = useMemo(
    () => ({
      expenses: +totalExpenses,
      expensesResico: +totalExpensesResico,
      incomes: +totalIncomes,
      incomesResico: +totalIncomesResico,
      payableTaxesResico: profitOrLossResico,
      payableTaxes: profitOrLoss,
      salaries: +totalIncomesSalaries,
    }),
    [
      totalExpenses,
      totalExpensesResico,
      totalIncomes,
      totalIncomesResico,
      profitOrLossResico,
      profitOrLoss,
      totalIncomesSalaries,
    ],
  );

  const declarationsByRegimen = useMemo(() => {
    if (!declarations) return [];
    const groupedByRegimen = declarations.reduce(
      (
        acc: Record<
          string,
          Record<string, Record<string, number> | string | number>
        >,
        declaration,
      ) => {
        const {
          start_date,
          declaration_income,
          declaration_expense,
          fiscal_regime,
        } = declaration;
        const incomes = +(declaration_income?.total ?? 0);
        const expenses = +(declaration_expense?.total ?? 0);
        const month = _.capitalize(dayjs(start_date).format('MMMM'));
        const regime = fiscal_regime?.alias || fiscal_regime?.description || '';
        const regimeSatKey = fiscal_regime?.sat_key;

        if (hasPfae && hasPlatforms) {
          const key = '612_625';
          if (!acc[key]) {
            acc[key] = {
              regimen: 'PFAE_PLATFORMS',
              regimeSatKey: '612_625',
              Enero: {
                expenses: 0,
                incomes: 0,
              },
              Febrero: {
                expenses: 0,
                incomes: 0,
              },
              Marzo: {
                expenses: 0,
                incomes: 0,
              },
              Abril: {
                expenses: 0,
                incomes: 0,
              },
              Mayo: {
                expenses: 0,
                incomes: 0,
              },
              Junio: {
                expenses: 0,
                incomes: 0,
              },
              Julio: {
                expenses: 0,
                incomes: 0,
              },
              Agosto: {
                expenses: 0,
                incomes: 0,
              },
              Septiembre: {
                expenses: 0,
                incomes: 0,
              },
              Octubre: {
                expenses: 0,
                incomes: 0,
              },
              Noviembre: {
                expenses: 0,
                incomes: 0,
              },
              Diciembre: {
                expenses: 0,
                incomes: 0,
              },
            };
          }

          (acc[key][month] as Record<string, number>).expenses += expenses;
          (acc[key][month] as Record<string, number>).incomes += incomes;
        } else {
          // For other regimeSatKeys, continue with the previous logic
          if (!acc[regime]) {
            acc[regime] = {
              regimen: regime,
              regimeSatKey,
              Enero: {
                expenses: 0,
                incomes: 0,
              },
              Febrero: {
                expenses: 0,
                incomes: 0,
              },
              Marzo: {
                expenses: 0,
                incomes: 0,
              },
              Abril: {
                expenses: 0,
                incomes: 0,
              },
              Mayo: {
                expenses: 0,
                incomes: 0,
              },
              Junio: {
                expenses: 0,
                incomes: 0,
              },
              Julio: {
                expenses: 0,
                incomes: 0,
              },
              Agosto: {
                expenses: 0,
                incomes: 0,
              },
              Septiembre: {
                expenses: 0,
                incomes: 0,
              },
              Octubre: {
                expenses: 0,
                incomes: 0,
              },
              Noviembre: {
                expenses: 0,
                incomes: 0,
              },
              Diciembre: {
                expenses: 0,
                incomes: 0,
              },
            };
          }

          if (acc[regime][month]) {
            (acc[regime][month] as Record<string, number>).expenses += expenses;
            (acc[regime][month] as Record<string, number>).incomes += incomes;
          }
        }

        return acc;
      },
      {},
    );

    return Object.values(groupedByRegimen);
  }, [declarations, hasPfae, hasPlatforms]);

  const hasPfaeAndPlatforms = hasPfae && hasPlatforms;

  const isLoading = declarationsLoading;

  return {
    period,
    declarations,
    declarationsByRegimen,
    isLoading,
    hasResico,
    hasPfae,
    hasPlatforms,
    totals,
    hasPfaeAndPlatforms,
    hasSalaries,
  };
}
