import { useMemo } from 'react';
import useComplianceOpinionStatus from 'shared/hooks/useComplianceOpinionStatus';
import useTaxableEntityShared from 'shared/hooks/useSharedTaxableEntity';
import getAuthorizationAxiosConfig from 'shared/util/getAuthorizationAxiosConfig';

export default function useInformationDashboard() {
  const { taxableEntityId, userAccessToken, taxableEntity } =
    useTaxableEntityShared();
  const authorizedAxiosConfig = getAuthorizationAxiosConfig(userAccessToken);
  const { complianceOpinionStatusLoading, complianceOpinionStatus } =
    useComplianceOpinionStatus(
      {
        params: {
          taxable_entity_id: taxableEntityId,
        },
        ...authorizedAxiosConfig,
      },
      {
        staleTime: 15 * 1000,
      },
    );
  return useMemo(() => {
    const hasComplianceOpinion = !!complianceOpinionStatus?.status;
    const taxableEntityRegimes = taxableEntity?.fiscal_regimes;
    const isResico =
      taxableEntityRegimes?.length === 1 &&
      taxableEntityRegimes[0].sat_key === 626;

    return {
      hasComplianceOpinion,
      complianceOpinionStatusLoading,
      isResico,
      complianceOpinionStatus,
    };
  }, [
    complianceOpinionStatusLoading,
    taxableEntity?.fiscal_regimes,
    complianceOpinionStatus,
  ]);
}
