import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ComplianceOpinionStatus } from 'types/entities';
import { COMPLIANCE_OPINION_STATUS } from 'backoffice/constants/reactQueries';
import { getComplianceOpinionStatus } from 'shared/api/documents';

export default function useComplianceOpinionStatus(
  axiosConfig?: AxiosRequestConfig<ComplianceOpinionStatus>,
  queryOptions?: UseQueryOptions<ComplianceOpinionStatus>,
) {
  const {
    data = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<ComplianceOpinionStatus>(
    [COMPLIANCE_OPINION_STATUS, axiosConfig],
    async () => {
      const complianceOpinionStatus = await getComplianceOpinionStatus(
        axiosConfig,
      );
      return complianceOpinionStatus;
    },
    queryOptions,
  );

  return {
    complianceOpinionStatusLoading: isLoading || isFetching,
    complianceOpinionStatus: data,
    ...rest,
  };
}
