export default function getAuthorizationAxiosConfig(userAccessToken: string) {
  if (userAccessToken) {
    return {
      headers: {
        Authorization: userAccessToken,
      },
    };
  }
  return {};
}
