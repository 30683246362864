import { Flex } from '@konta/ui';
import Main from '@components/DesignSystem/Main';
import Section from 'shared/components/Section';
import DeductionDetails from '@components/DeductionDetails';
import useFeature from '@hooks/useFeature';
import {
  ANNUAL_DEDUCTION_LIMITS,
  INSIGHTS_DASHBOARD,
} from '@constants/featureFlags';
import Heatmap from './Heatmap';
import Insights from './Insights';
import { Container, Root } from './styled';
import NotCiecModal from './NotCiecModal';
import { InsightsDialogProvider } from './Insights/InsightsDialogContext';
import useInformationDashboard from './useInformationDashboard';
import Header from './Header';
import LineSeries from './LineSeries';

export default function InformationDashboard() {
  const { hasComplianceOpinion, complianceOpinionStatusLoading, isResico } =
    useInformationDashboard();
  const isViewEnabled = hasComplianceOpinion;
  const [isInsightsEnabled] = useFeature(INSIGHTS_DASHBOARD);
  const [annualDeductionLimits] = useFeature(ANNUAL_DEDUCTION_LIMITS);

  return (
    <Root blockUi={!isViewEnabled}>
      {!isViewEnabled && (
        <NotCiecModal isLoading={complianceOpinionStatusLoading} />
      )}
      <Main blockUi={!isViewEnabled || complianceOpinionStatusLoading}>
        <Container>
          <Header />
          <Section alignStart>
            <Flex
              column
              css={{
                flex: 1,
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: 16,
                overflow: 'auto',
              }}
            >
              {isInsightsEnabled && (
                <Section>
                  <InsightsDialogProvider>
                    <Insights />
                  </InsightsDialogProvider>
                </Section>
              )}
              {annualDeductionLimits && !isResico && (
                <Section>
                  <DeductionDetails />
                </Section>
              )}
              <Section>
                <LineSeries />
              </Section>
            </Flex>
          </Section>
          <Section css={{ mt: '$18' }}>
            <Heatmap />
            <Flex css={{ flex: 1 }} />
          </Section>
        </Container>
      </Main>
    </Root>
  );
}
