import TaskCard from '@components/TaskCard';
import { Flex } from '@konta/ui';
import usePendingTasksStore from 'store/pendingTasksStore';
import { shallow } from 'zustand/shallow';
import TaskEmptyState from './TaskEmptyState';

interface TasksListProps {
  tab: 'requiredAction' | 'pending' | 'completed';
}

export default function TasksList({ tab }: TasksListProps) {
  const { pendingTasks } = usePendingTasksStore((state) => state, shallow);

  const filteredPendingTasks = pendingTasks.filter(
    (task) => task.status === tab,
  );

  if (filteredPendingTasks.length === 0) {
    return <TaskEmptyState tab={tab} />;
  }

  return (
    <Flex gap={12} direction="column">
      {filteredPendingTasks.map((task, index) => (
        <TaskCard key={index} task={task} />
      ))}
    </Flex>
  );
}
