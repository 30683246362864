import type { CellContext } from '@tanstack/react-table';
import type { DeclarationEntry } from 'types/entities';
import { CurrencyCell } from 'shared/util/table/cells';
import { getValue } from './values';
import { CurrencyType } from "./types";

export const getCurrencyCell = (type: CurrencyType) =>
  function Cell({ row }: CellContext<DeclarationEntry, unknown>) {
    const total = getValue(row.original, type);
    return <CurrencyCell value={total} />;
  };

