import api from '@api';
import { AxiosRequestConfig } from 'axios';
import {
  ComplianceOpinionDocument,
  ComplianceOpinionStatus,
} from 'types/entities';

export const getComplianceOpinion = async (taxableId: number) => {
  const { data } = await api.get<ComplianceOpinionDocument>(
    `taxable_entities/${taxableId}/compliance_opinion`,
  );
  return data;
};

export const getComplianceOpinionStatus = async (
  config?: AxiosRequestConfig<ComplianceOpinionStatus>,
) => {
  const { data } = await api.get<ComplianceOpinionStatus>(
    `compliance_opinions/last`,
    config,
  );
  return data;
};
