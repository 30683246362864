import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import getCurrentRouteByWorkflowPhase from '@util/getCurrentRouteByWorkflowPhase';
import { WorkflowPhaseKeyType } from 'types/workflow';
import isValidationDateValid from '@util/isValidationDateValid';
import { NotificationManager } from '@components/Notifications';
import getErrorMessage from '@util/getErrorMessage';
import postRequireCaptureLine from '@api/postRequireCaptureLine';
import getDocumentFromDeclaration from '@util/getDocumentFromDeclaration';
import { CaptureLineDocument } from 'types/entities';
import useHasPayrollRetention from '@hooks/useHasPayrollRetention';
import { showWorkflowFetch } from '@redux/workflows/actions';
import { ACCUMULATED_OF_ENTRIES } from '@constants/reactQueries';
import { DECLARATION_ENTRIES_BY_WORKFLOW } from 'shared/constants/reactQueries';
import useDeclarationTotalWithRegimenByType from './useDeclarationTotalWithRegimeByType';

export type ReturnTypeUseWorkflow = ReturnType<typeof useWorkflow>;

export default function useWorkflow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    hasPayrollRetentions,
    loadingPayrollRetention,
    taxableEntityPreferences,
  } = useHasPayrollRetention();

  const { workflow, workflowLoading } = useSelectedWorkflow();
  const requireCaptureLine = useMutation(postRequireCaptureLine);
  const currentWorkflowId = workflow?.id ?? 0;
  const activeDeclarations = useMemo(
    () => workflow?.active_declarations ?? [],
    [workflow?.active_declarations],
  );

  const currentRegimes = useMemo(
    () => activeDeclarations.map((declaration) => declaration.fiscal_regime),
    [activeDeclarations],
  );

  const currentRegimesWithDeclarationId = useMemo(
    () =>
      activeDeclarations.map((declaration) => ({
        ...declaration.fiscal_regime,
        declarationId: declaration.id,
      })),
    [activeDeclarations],
  );

  const {
    declarationsIncomeTotalByRegimen,
    declarationsExpenseTotalByRegimen,
  } = useDeclarationTotalWithRegimenByType(activeDeclarations);

  const regimeOptions = useMemo(
    () =>
      currentRegimes.map((regime) => ({
        label: getRegimeNameFromFiscalRegime(regime),
        value: regime.id.toString(),
        satKey: regime.sat_key,
      })),
    [currentRegimes],
  );

  const regimeOptionsWithDeclarationId = useMemo(
    () =>
      currentRegimesWithDeclarationId.map((regime) => ({
        label: getRegimeNameFromFiscalRegime(regime),
        value: regime.id.toString(),
        declarationId: regime.declarationId,
        satKey: regime.sat_key,
      })),
    [currentRegimesWithDeclarationId],
  );

  const defaultRegimeOptionBySatkey = useMemo(
    () =>
      regimeOptionsWithDeclarationId.find(
        (option) =>
          option.satKey === taxableEntityPreferences?.preferred_fiscal_regime ||
          option.value === '4',
      ),
    [regimeOptionsWithDeclarationId, taxableEntityPreferences],
  );
  const handleCloseFlow = () => {
    if (!workflow) {
      return;
    }
    const route = getCurrentRouteByWorkflowPhase(
      WorkflowPhaseKeyType.PendingDeclaration,
      workflow,
    );
    navigate(route);
  };

  const handleRequireCaptureLine = useCallback(async () => {
    try {
      const expiredDeclarations = activeDeclarations.filter(
        (declaration) =>
          !isValidationDateValid(
            declaration.declaration_document?.validation_date,
          ),
      );
      await Promise.all(
        expiredDeclarations.map(async (declaration) => {
          await requireCaptureLine.mutateAsync({
            declarationId: declaration.id,
            workflowId: currentWorkflowId,
          });
        }),
      );
      NotificationManager.success(
        'Se ha solicitado la línea de captura correctamente',
        'Línea de captura',
      );
    } catch (e) {
      const message = getErrorMessage(e) as string;
      NotificationManager.error(message, 'Error');
    }
  }, [activeDeclarations, currentWorkflowId, requireCaptureLine]);

  const declarationsDocuments = useMemo(() => {
    const documents = activeDeclarations.map((declaration) =>
      getDocumentFromDeclaration(declaration),
    );
    if (hasPayrollRetentions && activeDeclarations.length > 0) {
      documents.push(getDocumentFromDeclaration(activeDeclarations[0], true));
    }

    return documents.filter(Boolean) as CaptureLineDocument[];
  }, [activeDeclarations, hasPayrollRetentions]);

  const grandTotalToPay = useMemo(
    () =>
      declarationsDocuments.reduce(
        (acc, document) => acc + +document.totalToPay,
        0,
      ),
    [declarationsDocuments],
  );

  const updateCurrentWorkflow = () => {
    dispatch(showWorkflowFetch(currentWorkflowId));
  };

  const updateWorkflowGraphs = async () => {
    await queryClient.invalidateQueries([ACCUMULATED_OF_ENTRIES]);
  };

  const updateDeclarationEntries = async () => {
    await queryClient.invalidateQueries([DECLARATION_ENTRIES_BY_WORKFLOW]);
  };

  const updateAllWorkflow = async () => {
    await updateWorkflowGraphs();
    await updateDeclarationEntries();
  };

  const totalDeclarationIncomes = useMemo(
    () =>
      activeDeclarations.reduce(
        (acc, declaration) => acc + +declaration.declaration_income.total,
        0,
      ),
    [activeDeclarations],
  );
  const totalDeclarationExpenses = useMemo(
    () =>
      activeDeclarations.reduce(
        (acc, declaration) => acc + +declaration.declaration_expense.total,
        0,
      ),
    [activeDeclarations],
  );

  const preferredFiscalRegime = useMemo(
    () =>
      currentRegimes.find(
        (regime) =>
          regime.sat_key === taxableEntityPreferences?.preferred_fiscal_regime,
      ),
    [currentRegimes, taxableEntityPreferences?.preferred_fiscal_regime],
  );

  const currentPreferredRegimes = useMemo(() => {
    if (preferredFiscalRegime) {
      return [preferredFiscalRegime];
    }
    return currentRegimes;
  }, [currentRegimes, preferredFiscalRegime]);

  const isLoadingWorkflow = workflowLoading;

  return {
    workflow,
    activeDeclarations,
    currentRegimes,
    isLoadingWorkflow,
    workflowId: currentWorkflowId,
    declarationsExpenseTotalByRegimen,
    declarationsIncomeTotalByRegimen,
    regimeOptions,
    regimeOptionsWithDeclarationId,
    requireCaptureLineLoading: requireCaptureLine.isLoading,
    handleCloseFlow,
    handleRequireCaptureLine,
    declarationsDocuments,
    grandTotalToPay,
    loadingPayrollRetention,
    updateCurrentWorkflow,
    totalDeclarationIncomes,
    totalDeclarationExpenses,
    defaultRegimeOptionBySatkey,
    taxableEntityPreferences,
    updateAllWorkflow,
    updateWorkflowGraphs,
    updateDeclarationEntries,
    preferredFiscalRegime,
    currentPreferredRegimes,
  };
}
