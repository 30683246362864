import { useState } from 'react';
import { Flex, Icon, Tab, Tabs, Text, Chip } from '@konta/ui';
import { Divider } from 'backoffice/modules/customer/Customer/styled';
import {
  CheckCircleLineIcon,
  Hourglass03LineIcon,
  Inbox01LineIcon,
} from '@konta/icons';
import usePendingTasksStore from 'store/pendingTasksStore';
import Loader from '@components/Loader';
import TasksList from './TasksList';
import usePendingTask from './usePendingTask';
import PreOnboardingHomeCardModal from './PreOnboardingHomeCardModal';
import DebtsOrdersModal from './DebtsOrdersModal';
import ProcessModal from './ProcessModal/ProcessModal';

const tabCss = {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '&:hover': {
    cursor: 'pointer',
  },
};

const tabIconCss = {
  svg: {
    path: {
      fill: 'none',
    },
  },
};
export default function PendingTasks() {
  const [tab, setTab] = useState<'requiredAction' | 'pending' | 'completed'>(
    'requiredAction',
  );
  const { selectedTask } = usePendingTasksStore();

  const {
    showPreOnboardingModalTask,
    toggleShowPreOnboardingModalTask,
    filteredPendingTasks,
    showDebsOrderModalTask,
    toggleShowDebsOrderModalTask,
    tasksLoading,
  } = usePendingTask();

  const availableProcess = ['onboarding', 'annualDeclaration'];

  if (tasksLoading) {
    return (
      <Flex
        direction="column"
        css={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </Flex>
    );
  }

  return (
    <>
      <Flex gap={24} direction="column" className="app-container">
        <Flex direction="column">
          <Flex
            direction="column"
            css={{ background: '#fff', width: '100%' }}
            gap={12}
          >
            <Text x3l bold data-cy="pending-tasks-title">
              Tareas pendientes
            </Text>
            <Text l color="gray700">
              Supervisa y completa tus tareas asignadas.
            </Text>
          </Flex>
          <Divider
            css={{
              my: '$24',
            }}
          />
          <Flex>
            <Tabs
              value={tab}
              onChange={setTab}
              variant="switch"
              indicator="bottom"
            >
              <Tab id="requiredAction" css={tabCss}>
                <Icon css={tabIconCss} size={24}>
                  <Inbox01LineIcon />
                </Icon>
                <Text s>Acción requerida</Text>
                <Chip
                  label={filteredPendingTasks.requiredAction.length.toString()}
                  color="gray100"
                />
              </Tab>
              <Tab id="pending" css={tabCss}>
                <Icon css={tabIconCss} size={24}>
                  <Hourglass03LineIcon />
                </Icon>
                <Text s>En espera de konta</Text>
                <Chip
                  label={filteredPendingTasks.pending.length.toString()}
                  color="gray100"
                />
              </Tab>
              <Tab id="completed" css={tabCss}>
                <Icon css={tabIconCss} size={24}>
                  <CheckCircleLineIcon />
                </Icon>
                <Text s>Completadas</Text>
                <Chip
                  label={filteredPendingTasks.completed.length.toString()}
                  color="gray100"
                />
              </Tab>
            </Tabs>
          </Flex>
        </Flex>

        <TasksList tab={tab} />
      </Flex>
      <PreOnboardingHomeCardModal
        openModal={!!showPreOnboardingModalTask}
        toggleOpenModal={toggleShowPreOnboardingModalTask}
      />
      <DebtsOrdersModal
        openModal={!!showDebsOrderModalTask}
        toggleOpenModal={toggleShowDebsOrderModalTask}
      />

      {availableProcess.includes(selectedTask?.type || '') && <ProcessModal />}
    </>
  );
}
