import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  TAXABLE_ENTITY_UPDATE,
  TAXABLE_ENTITY_FIEL_UPLOAD,
  TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD,
  TAXABLE_ENTITY_CSD_UPLOAD,
  TAXABLE_ENTITY_CREDENTIALS_FETCH,
} from '@constants/actionTypes';
import {
  updateTaxableEntityWithAccessToken,
  uploadTaxableEntityFielWithAccessToken,
  uploadTaxableEntityPayrollCredentialsWithAccessToken,
  uploadTaxableEntityCsd,
} from './update';
import fetchTaxableEntityCredentials from './getCredentials';

export function* watchUpdateTaxableEntity() {
  yield takeLatest(TAXABLE_ENTITY_UPDATE, updateTaxableEntityWithAccessToken);
}

export function* watchUploadTaxableEntityFiel() {
  yield takeLatest(
    TAXABLE_ENTITY_FIEL_UPLOAD,
    uploadTaxableEntityFielWithAccessToken
  );
}
export function* watchUploadTaxableEntityPayrollCredentials() {
  yield takeLatest(
    TAXABLE_ENTITY_PAYROLL_CREDENTIALS_UPLOAD,
    uploadTaxableEntityPayrollCredentialsWithAccessToken
  );
}

export function* watchUploadTaxableEntityCsd() {
  yield takeLatest(TAXABLE_ENTITY_CSD_UPLOAD, uploadTaxableEntityCsd);
}

export function* watchfetchTaxableEntityCredentials() {
  yield takeLatest(
    TAXABLE_ENTITY_CREDENTIALS_FETCH,
    fetchTaxableEntityCredentials
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchUpdateTaxableEntity),
    fork(watchUploadTaxableEntityFiel),
    fork(watchUploadTaxableEntityPayrollCredentials),
    fork(watchUploadTaxableEntityCsd),
    fork(watchfetchTaxableEntityCredentials),
  ]);
}
